.c-toc {
    font-family: $c-toc-font;
    position: relative;
    @include font-size($c-toc-item-font-size);
    @include mq($from: xl) {
        display: $c-toc-display;
        @if $c-toc-display == flex {
            flex-direction: $c-toc-flex-direction;
            flex-wrap: wrap;
        }
    }
    //@include mq($from: xl) {
    //    @include font-size($c-toc-item-font-size-sm);
    //    @if $c-toc-display == flex {
    //        flex-wrap: nowrap;
    //    }
    //}
    @include list-reset();

}

.c-toc__item {
    display: none;
    &--has-subtree {
        display: $c-toc-item-display;
        border-right: 1px solid #fff;
        @if $c-toc-item-display == flex {
            flex-wrap: wrap;
            @include mq($from: xl) {
                flex-direction: $c-toc-item-flex-direction;
            }
        }
    }
    //&.presse-kommunikation {
    //    display: none;
    //}
    &.en {
        display: none;
    }
    padding: .35rem 0 .35rem 0;
    text-transform: none;
    line-height: $c-toc-item-line-height;
    @include when-inside('.c-toc--level-1') {
        display: $c-toc-item-display;
        @if $c-toc-item-display == flex {
            @include mq($from: xl) {
                flex-direction: $c-toc-item-flex-direction;
                flex: 1 0 100%;
                max-width: 100%;
            }
        }
        text-transform: none;
        margin-bottom: 0;
        border-right: 0;
        padding-left: 0;
        padding-right: 0;
    }
    //@include mq($from: xl) {
    //    flex: 1 0 50%;
    //    max-width: 50%;
    //    margin-bottom: 1.5rem;
    //    padding: .35rem 0 .35rem 0;
    //}
    //@include mq($from: xl) {
    //    flex: 1 0 33.3333%;
    //    max-width: 33.333%;
    //    margin-bottom: 1.5rem;
    //    padding: .35rem 0 .35rem 0;
    //}
    @include mq($from: xl) {
        padding: .3rem 1.5rem;
        flex: 1 0 16.6666%;
        max-width: 16.6666%;
        margin-bottom: 0;
        &:first-of-type {
            padding-left: 0;
        }
    }
}

.c-toc__item--has-subtree {
    border: 0px solid hsla(0,0%,100%,.1);
    @include mq($from: xl) {
        &:nth-child(even) {
            border-left-width: 2px;
        }
    }
    @include mq($from: xl) {
        &:not(:first-child) {
            border-left-width: 2px;
        }
    }
}

.c-toc--level-1 {
    display: none;
    @include mq($until: xl) {
        flex: 1 0 100%;
        max-width: 100%;
    }
    @include when-inside('.show') {
        display: block;
    }
    @include mq($from: xl) {
        display: block;
    }
    text-transform: none;
    // font-family: $app-font-family-medium;
}

.c-toc__link {
    display: flex;
    width: 100%;
    min-height: calc(#{$app-font-size-base} * 1.5);
    line-height: 1.25;
    @include mq($from: xl) {
        display: block;
        height: calc(#{$app-font-size-base} * 2.5);
        text-decoration: none;
        cursor: pointer;
    }
    color: $c-toc-link-color;
    font-size: $c-toc-item-font-size;
    @include when-inside('.c-toc--level-1') {
        display: block;
        height: auto;
        font-size: $c-toc-item-font-size-sm;
    }
    &:hover {
        color: $c-toc-link-color--hover;
    }
}

.c-toc__link--toggle {
    text-decoration: none;
    cursor: default;
    &:hover {
        text-decoration: none;
    }
}

.c-toc__link-item {
    flex: 1 0 50%;
    max-width: 50%;
    line-height: 1.2rem;
    &--icon {
        text-align: right;
        color: $c-toc-link-color;
        @include mq($from: xl) {
            display: none;
        }
    }
}

.c-toc__toggle {
    display: block;
    flex: 1 0 50%;
    color: $c-toc-link-color;
    text-align: right;
    @include mq($from: xl) {
        display: none;
    }
}

.c-toc__icon {
    color: currentColor;
    &--close {
        display: none;
    }
    @include when-inside('.show') {
        &--open {
            display: none;
        }
        &--close {
            display: inline-block;
        }
    }
}
