.o-page {
    display: block;
    position: relative;
    padding-right: $app-spacer-base;
    padding-left: $app-spacer-base;
    width: 100%;
    @include mq($from: lg) {
        padding-right: 0;
        padding-left: 0;
    }
    @include when-inside('body:not(.portaltype-hph-sitecontent-mainsection)') {
        &:not(.o-page--panels) {
            margin-right: auto;
            margin-left: auto;
            @include mq($from: lg) {
                @include make-container-max-widths();
            }
        }
    }
    @include when-inside('body:not(.portaltype-ade25-panelpage-page)') {
        &:not(.o-page--panels) {
            margin-right: auto;
            margin-left: auto;
            @include mq($from: lg) {
                @include make-container-max-widths();
            }
        }
    }
}

.o-page--panels {
    padding-left: 0;
    padding-right: 0;
    @include when-inside('.userrole-authenticated') {
        padding-top: 4rem;
    }
}

.o-page--sign-in {
    display: flex;
    max-width: 100% !important;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    background: linear-gradient(rgba(255,255,255,1), rgba(239,239,239,1));
}

// Page components
// (should ideally only contain basic layout definitions)
.o-page__header {
    margin-bottom: $o-page-header-margin-bottom;
}

.o-page__header-action {
    @include mq($from: lg) {
        text-align: right;
    }
}

.o-page__main {
    margin-right: auto;
    margin-left: auto;
    @include make-container-max-widths();
}

.o-page__section {
    position: relative;
    margin-bottom: $app-spacer-base;
    @include when-inside('.template-course-view') {
        @include mq($from: lg) {
        width: 80%;
        }
    }
    &--spacer {
        margin-bottom: calc(#{$app-spacer-base} * 2);
    }
}

.o-page__headline {
    padding: .125rem 0;
    color: #000;
    line-height: 1.25;
}


.o-page__asset {
    width: 100%;
    &--md {
        @include mq($until: md) {
            max-width: 50%;
        }
    }
}

.o-page__asset--inline {
    margin-bottom: $app-spacer-base;
    @include mq($from: md) {
        margin-right: $app-spacer-sm;
    }
}
