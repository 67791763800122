.c-pane {
  margin: 0;
}

.c-pane__header {
  width: 100%;
  cursor: pointer;
}

.c-pane__title {
  display: flex;
  width: 100%;
  padding: $app-spacer-sm;
  margin-bottom: 0;
  border-bottom: 1px solid $app-color-gray-light;
  background-color: $app-color-gray-lightest;
}

.c-pane__headline {
  flex: 0 1 calc(100% - 4rem);
}

.c-pane__toggle {
  flex: 0 1 4rem;
  text-align: right;
}

.c-pane__main {
  display: none;
  transition: ease .3s all;
  animation-name: fadeIn;
  animation-duration: .6s;
  animation-fill-mode: both;
  will-change: transform;
  &--active,
  &.c-collapsible__item--active {
    display: block;
  }
}

.c-pane__content {
  display: block;
  padding: $app-spacer-base $app-spacer-sm;
}

.c-pane__icon {
  color: currentColor;
  &--close {
    display: none;
  }
  @include when-inside('.show') {
    &--open {
      display: none;
    }
    &--close {
      display: inline-block;
    }
  }
}