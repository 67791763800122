.hll { background-color: #ffffcc }
.c { color: #999; }
.err { color: #AA0000; background-color: #FFAAAA }
.k { color: #006699; }
.o { color: #555555 }
.cm { color: #999; }
.cp { color: #009999 }
.c1 { color: #999; }
.cs { color: #999; }
.gd { background-color: #FFCCCC; border: 1px solid #CC0000 }
.ge { font-style: italic }
.gr { color: #FF0000 }
.gh { color: #003300; }
.gi { background-color: #CCFFCC; border: 1px solid #00CC00 }
.go { color: #AAAAAA }
.gp { color: #000099; }
.gu { color: #003300; }
.gt { color: #99CC66 }
.kc { color: #006699; }
.kd { color: #006699; }
.kn { color: #006699; }
.kp { color: #006699 }
.kr { color: #006699; }
.kt { color: #007788; }
.m { color: #FF6600 }
.s { color: #d44950 }
.na { color: #4f9fcf }
.nb { color: #336666 }
.nc { color: #00AA88; }
.no { color: #336600 }
.nd { color: #9999FF }
.ni { color: #999999; }
.ne { color: #CC0000; }
.nf { color: #CC00FF }
.nl { color: #9999FF }
.nn { color: #00CCFF; }
.nt { color: #2f6f9f; }
.nv { color: #003333 }
.ow { color: #000000; }
.w { color: #bbbbbb }
.mf { color: #FF6600 }
.mh { color: #FF6600 }
.mi { color: #FF6600 }
.mo { color: #FF6600 }
.sb { color: #CC3300 }
.sc { color: #CC3300 }
.sd { color: #CC3300; font-style: italic }
.s2 { color: #CC3300 }
.se { color: #CC3300; }
.sh { color: #CC3300 }
.si { color: #AA0000 }
.sx { color: #CC3300 }
.sr { color: #33AAAA }
.s1 { color: #CC3300 }
.ss { color: #FFCC33 }
.bp { color: #336666 }
.vc { color: #003333 }
.vg { color: #003333 }
.vi { color: #003333 }
.il { color: #FF6600 }

.css .o,
.css .o + .nt,
.css .nt + .nt { color: #999; }
