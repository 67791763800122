///* ========================================================================
//   #CONFIG
//   ======================================================================== */
// Custom web font
// $app-font-family-base:              "EB Garamond";
$app-font-family-base:              "adobe-garamond-pro";
$app-font-family-light:             "adobe-garamond-pro";
$app-font-family-medium:            "Taz-SemiLight";
$app-font-family-strong:            "Taz-SemiBold";

// Configure site font stack
$app-font-family-serif:             $app-font-family-base, Garamont, 'Times New Roman', Times, serif;
$app-font-family-sans-serif:        'Arial Narrow', Arial;

$app-font-weight-base-custom:       $app-font-weight-base;
$app-font-weight-strong-custom:     700;
