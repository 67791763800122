.c-language-menu {
    display: $c-quick-links-display;
    position: relative;
    flex: 1 0 50%;
    max-width: 50%;
    padding-right: $app-spacer-base;
    @include mq($from: lg) {
        padding-right: 0;
    }
}

.c-language-menu__button {
    display: flex;
    //flex-direction: $c-quick-links-flex-direction;
    flex-direction: row-reverse;
    line-height: 1;
    background: $c-quick-links-background-color; // remove default button style
    border: $c-quick-links-border-width $c-quick-links-border-style $c-quick-links-border-color; // remove default button style
    cursor: pointer;
    @include mq($from: lg) {
        width: 100%;
        justify-content: center;
    }
}

.c-language-menu__text {
    align-self: flex-end;
    font-family: $c-quick-links-font;
    font-size: $c-quick-links-text-font-size-small;
    color: $c-quick-links-text-color;
    text-transform: $c-quick-links-text-transform;
    @include mq($from: lg) {
        font-size: $c-quick-links-text-font-size;
    }
}

.c-language-menu__icon {
    margin-right: .5rem;
    font-size: $c-quick-links-icon-font-size;
    color: $c-quick-links-icon-color;
    width: 1rem;
    height: 1.3rem;
    @include mq($from: lg) {
        width: 1.3rem;
        height: 1.6rem;
    }
}
