//
// Footer
// -----------------------------------

.app-footer {
    padding-top: $spacer;
    padding-bottom: $spacer;
}

.app-footer__content {
    padding: $app-spacer-base;
    &--primary {
        color: $white;
        background-color: $app-color-primary;
    }
}

.app-footer__main {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    @include make-container-max-widths();
    @include mq($from: lg) {
        padding: 0;
    }
}

.app-footer__aside {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    @include make-container-max-widths();
    @include mq($from: lg) {
        padding: 0;
    }
}
