$c-meta-nav-display:                block;
$c-meta-nav-item-display:           inline-block;

$c-meta-nav-color:                  $app-color-text;
$c-meta-nav-color-highlight:        darken($c-meta-nav-color, 15%);

$c-meta-nav-font-family:            $app-font-family-strong;
$c-meta-nav-font-size:              $app-font-size-sm;

.c-meta-nav {
    display: $c-meta-nav-display;
    padding-left: .25rem;
    padding-right: .25rem;
    text-align: center;
    @include mq($from: md) {
        padding-left: 0;
        text-align: left;
    }
}

.c-meta-nav__item {
    display: $c-meta-nav-item-display;
    &:not(:last-child):after {
        content:'\00B7';
        display: inline-block;
        margin-left: .25rem;
        margin-right: .25rem;
    }
}

.c-meta-nav__link {
    color: $c-meta-nav-color;
    font-family: $c-meta-nav-font-family;
    @include font-size($c-meta-nav-font-size);
    &:hover {
        color: $c-meta-nav-color-highlight;
    }
}
