.c-widget {
    display: block;
    &--card {
        width: 100%;
    }
}
.c-widget__header {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
    color: #fff;
    background-color: #000;
}

.c-widget__content {


    &--header {
        @include make-container-max-widths();
        font-family: $app-font-family-strong;
        @include font-size(2rem);
    }
}

.c-widget__icon {
    display: $c-panel-action-display;
    @if $c-panel-action-display == flex {
        align-items: center;
        justify-content: center;
    }
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
    border: 1px solid $gray-500;
    margin-bottom: $app-spacer-sm;
    &--centered {
        margin-left: auto;
        margin-right: auto;
    }
    &--default {
        width: 4rem;
        height: 4rem;
    }
}

.c-widget__editor {
    display: block;
    &:hover {
        cursor: text;
    }
}
