$c-nav-list-padding:                            .5rem;
$c-nav-list-color-bg:                           $app-color-gray-light;
$c-nav-list-color-bg-highlight:                 $app-color-gray-base;

.c-nav-list {
    @include list-reset();
    font-family: $app-font-family-strong;
}

.c-nav-list__item {
    border-bottom: 1px solid #fff;
    background-color: $c-nav-list-color-bg;
}

.c-nav-list__link {
    &--open {
        //border-left: 3px solid red;
    }
}

.c-nav-list__link {
    display: block;
    width: 100%;
    padding: $c-nav-list-padding calc(#{$c-nav-list-padding} * 2);
    transition: none;
    &--active {
        color: #fff;
        background-color: $app-color-gray-lighter;
        &:hover {
            color: #fff;
            transition: none;
            text-decoration: none;
        }
    }
    

    @include when-inside('.c-nav-list--level-0') {
        padding-left: calc(#{$c-nav-list-padding} * 2);
    }
    @include when-inside('.c-nav-list--level-1') {
        padding-left: calc(#{$c-nav-list-padding} * 3);
    }
    @include when-inside('.c-nav-list--level-2') {
        padding-left: calc(#{$c-nav-list-padding} * 4);
    }
}
