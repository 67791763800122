///* ========================================================================
//   #GLOBAL
//   ======================================================================== */
// The global settings file contains any project-wide variables; things that
// need to be made available to the entire codebase.

$enable-responsive-font-sizes:              true;

$font-size-small:                   1rem;
$font-size-normal:                  1.25rem;
$font-size-large:                   1.5rem;


$app-line-height-lg:                1.5;
$app-line-height-base:              1.5;
$app-line-height-sm:                1.5;

// Elements


// WYSIWYG Styles
$app-wysiwyg-editor-padding: 1rem;


