$c-poster-color:                  $app-color-white;
$c-poster-padding:                $app-spacer-base;

.c-poster {
  display: block;
  position: relative;
  width: 100%;
}

.c-poster__main {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 1rem;
  color: $c-poster-color;
  @include mq($from: md) {
    padding: 3rem;
  }
}

.c-poster__header {
  font-family: $app-font-family-strong;
  font-size: $app-font-size-h4;
  line-height: $app-line-height-sm;
  @include mq($from: md) {
    line-height: $app-line-height-base;
  }
}

.c-poster__description {
  font-size: $app-font-size-base;
  line-height: $app-line-height-sm;
}

.c-poster__link {
  color: $c-poster-color;
  text-decoration: none;
  &:hover {
    color: $c-poster-color;
  }
}

//.o-figure__card {
//    @include when-inside('.c-poster__aside') {
//        &::before {
//            @include mq($until: md) {
//                padding-bottom: 100%;
//            }
//        }
//    }   
//}

.c-poster__text {
  a {
    color: $c-poster-color;
  }
  a:hover {
    color: $c-poster-color;
  }
}
