$c-snippet-display:                 flex;
$c-snippet-aside-width:             25%;
$c-snippet-main-width:              75%;
$c-snippet-flex-direction:          row-reverse;
$c-snippet-width:                   100%;
$c-snippet-padding:                 $app-font-size-base;
$c-snippet-padding-sm:              calc(#{$app-font-size-base} / 2);
//$c-snippet-margin-base:             calc(#{$app-font-size-base} * 1.5);
$c-snippet-margin-base:             $app-font-size-base;

$c-snippet-color:                   $app-color-text;


.c-snippet {
    width: $c-snippet-width;
    display: $c-snippet-display;
    flex-direction: column-reverse;
    margin-bottom: $c-snippet-margin-base;
    @include mq($from: md) {
        flex-direction: $c-snippet-flex-direction;
        flex-wrap: wrap;
    }
}

.c-snippet__aside {
    flex: 1 0 100%;
    padding-bottom: $c-snippet-padding;
    @include mq($from: md) {
        flex: 1 0 $c-snippet-aside-width;
        max-width: $c-snippet-aside-width;
        padding-right: $c-snippet-padding;
        padding-bottom: 0;
    }
}

.c-snippet__main {
    display: flex;
    flex-direction: column;
    flex: 1 0 100%;
    padding-bottom: $c-snippet-padding;
    @include mq($from: md) {
        flex: 1 0 $c-snippet-main-width;
        max-width: $c-snippet-main-width;
        padding-bottom: $c-snippet-padding-sm;
    }
}

.c-snippet__footer {
    @include font-size($app-font-size-sm);
    font-family: $app-font-family-strong;
    padding-top: $c-snippet-padding-sm;
}


// Snippet elements
.c-snippet__title {
    font-weight: 500;
}

.c-snippet__section {
    @include font-size($app-font-size-base);
}

.c-page-department {
    font-family: $app-font-family-strong;
    margin-bottom: 1rem;
}

.c-snippet__abstract {
    color: $c-snippet-color;
    @include font-size($app-font-size-base);
}

.c-snippet__link {
    display: block;
    &--abstract {
        color: $c-snippet-color;
        &:hover {
            color: $c-snippet-color;
            text-decoration: none;
        }
    }
}

.c-snippet__figure {
    &--constrained-width-md,
    &--faculty {
        @include mq($from: sm, $until: md) {
            max-width: 50%;
        }
    }
}
