$app-main-width:                    100%;
$app-main-padding-top:              8rem;

// App main section
.app-main {
    width: $app-main-width;
    padding-top: $app-main-padding-top;
    margin-left: auto;
    margin-right: auto;
    @include when-inside('.template-frontpage-view') {
        padding-top: 0;
    }
    &.u-no-scroll {
        position: fixed;
        left: 0;
        right: 0;
        overflow-y: scroll; /* render disabled scroll bar to keep the same width */
    }
    @include mq($from: md, $until: lg) {
        padding-top: calc(#{$app-main-padding-top} + 2rem );
    }
}

.app-main--fullscreen {
    padding: 0;
}

.app-main--grid {
    @include make-container-max-widths();
    @include mq($from: lg) {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row-reverse;
        justify-content: space-between;
    }
}

.app-sidebar {
    @include when-inside('.app-main--grid') {
        @include mq($from: lg) {
            flex: 1 0 25%;
            max-width: 25%;
        }
    }
}

.app-content {
    @include when-inside('.app-main--grid') {
        @include mq($from: lg) {
            flex: 1 0 75%;
            max-width: 75%;
            padding-right: $app-spacer-base;
        }
    }
}
