/* Dropdown container
-------------------------------------------------------------- */

.dropmic {
    position: relative;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
}

.dropmic * {
    box-sizing: border-box;
}

/* Dropdown menu
-------------------------------------------------------------- */

.dropmic-menu {
    position: absolute;
    z-index: 1;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    min-width: 10vw;
    max-width: 50vw;
    max-height: 90vh;
    width: max-content;
    // border: 1px solid #d6d5d5;
    // border-radius: 3px;
    // background: #fff;
}

@media (max-width: 420px) {
    .dropmic-menu {
        max-width: 100vw;
    }
}

.dropmic--show .dropmic-menu {
    clip: inherit;
}

.dropmic-menu,
    /* Default behavior */
[data-dropmic-direction="bottom-right"] .dropmic-menu {
    top: auto;
    right: auto;
    bottom: 0;
    left: 30px;
    transform: translateY(calc(100% + 10px));
    @include mq($from: lg) {
        left: 0;
    }
}

[data-dropmic-direction="bottom-left"] .dropmic-menu {
    top: auto;
    right: 0;
    bottom: 0;
    left: auto;
    transform: translateY(calc(100% + 10px));
}

[data-dropmic-direction="top-left"] .dropmic-menu {
    top: 0;
    right: 0;
    bottom: auto;
    left: auto;
    transform: translateY(calc(-100% - 10px));
}

[data-dropmic-direction="top-right"] .dropmic-menu {
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    transform: translateY(calc(-100% - 10px));
}

[data-dropmic-direction="bottom-middle"] .dropmic-menu {
    top: auto;
    right: auto;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%) translateY(calc(100% + 10px));
}

[data-dropmic-direction="top-middle"] .dropmic-menu {
    top: 0;
    right: auto;
    bottom: auto;
    left: 50%;
    transform: translateX(-50%) translateY(calc(-100% - 10px));
}

/* arrow */

.dropmic::before {
    position: absolute;
    width: 0;
    height: 0;
    background-size: 100% 100%;
    content: "";
}

.dropmic::before,
.dropmic::after {
    position: absolute;
    z-index: 1;
    display: none;
    width: 0;
    height: 0;
    background-size: 100% 100%;
    content: "";
}

.dropmic--show::before,
.dropmic--show::after {
    display: none;
}

.dropmic::before,
    /* Default behavior */
[data-dropmic-direction="bottom-left"]::before,
[data-dropmic-direction="bottom-right"]::before,
[data-dropmic-direction="bottom-middle"]::before {
    top: calc(100% + 10px);
    border: 7px solid transparent;
    border-bottom-color: #d6d5d5;
    transform: translateY(-100%);
}

.dropmic::after,
    /* Default behavior */
[data-dropmic-direction="bottom-left"]::after,
[data-dropmic-direction="bottom-right"]::after,
[data-dropmic-direction="bottom-middle"]::after {
    top: calc(100% + 11px);
    border: 7px solid transparent;
    border-bottom-color: #fff;
    transform: translateY(-100%);
}

.dropmic::before,
    /* Default behavior */
.dropmic::after,
    /* Default behavior */
[data-dropmic-direction="bottom-right"]::before,
[data-dropmic-direction="bottom-right"]::after {
    right: calc(50% - 4px);
}

[data-dropmic-direction="bottom-left"]::before,
[data-dropmic-direction="bottom-left"]::after {
    right: calc(50% - 4px);
}

[data-dropmic-direction="bottom-middle"]::before,
[data-dropmic-direction="bottom-middle"]::after {
    right: auto;
    left: calc(50% - 3px);
    transform: translateX(-50%) translateY(-100%);
}

[data-dropmic-direction="top-left"]::before,
[data-dropmic-direction="top-right"]::before,
[data-dropmic-direction="top-middle"]::before {
    top: -24px;
    bottom: -1px;
    border: 7px solid transparent;
    border-top-color: #d6d5d5;
    transform: translateY(100%);
}

[data-dropmic-direction="top-left"]::after,
[data-dropmic-direction="top-right"]::after,
[data-dropmic-direction="top-middle"]::after {
    top: -25px;
    bottom: 0;
    border: 7px solid transparent;
    border-top-color: #fff;
    transform: translateY(100%);
}

[data-dropmic-direction="top-left"]::before,
[data-dropmic-direction="top-left"]::after {
    right: calc(50% - 5px);
    left: auto;
}

[data-dropmic-direction="top-right"]::before,
[data-dropmic-direction="top-right"]::after {
    right: auto;
    left: calc(50% - 7px);
}

[data-dropmic-direction="top-middle"]::before,
[data-dropmic-direction="top-middle"]::after {
    right: auto;
    left: 50%;
    transform: translateX(-50%) translateY(100%);
}

/* Dropdown menu content
-------------------------------------------------------------- */

/* Content style */
.dropmic-menu__custom,
.dropmic-menu__listContent,
.dropmic-menu__listContent:link,
.dropmic-menu__listContent:visited {
    display: block;
    overflow: hidden;
    width: 100%;
    border: none;
    border-radius: 0;
    background: none;
    color: #373737;
    text-align: left;
    text-decoration: none;
    text-overflow: ellipsis;
    font-size: 1em;
    font-family: "Open Sans", "Source Sans Pro", Tahoma, sans-serif;
    cursor: pointer;
}

.dropmic-menu__custom {
    padding: 15px 30px;
    cursor: inherit;
}

.dropmic-menu__list,
.dropmic-menu__listItem {
    margin: 0;
    padding: 0;
    list-style: none;
}

.dropmic-menu__listContent {
    padding: 10px 30px;
}

/* When list come after custom content */

.dropmic-menu__listContent:nth-child(n) {
    border-top: 1px solid #f2f2f2;
}

/* When list come first */

.dropmic-menu__list:first-child .dropmic-menu__listContent {
    border-top: none;
    border-bottom: 1px solid #f2f2f2;
}

/* When the are only list items¨*/

.dropmic-menu__list:only-child .dropmic-menu__listItem:last-child .dropmic-menu__listContent {
    border-bottom: none;
}

/* States */
.dropmic-menu__listContent:hover,
.dropmic-menu__listContent:active {
    background-color: #f2f2f2;
    color: #000;
}
