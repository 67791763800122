[style*="--aspect-ratio"] > :first-child {
    width: 100%;
}
[style*="--aspect-ratio"] > img {
    height: auto;
}
@supports (--custom:property) {
    [style*="--aspect-ratio"] {
        position: relative;
    }
    [style*="--aspect-ratio"]::before {
        content: "";
        display: block;
        padding-bottom: calc(100% / (var(--aspect-ratio)));
    }
    [style*="--aspect-ratio"] > :first-child {
        position: absolute;
        top: 0;
        left: 0;
        height: auto;
    }
}

@supports ( not (--custom:property)) {
    [style*="--aspect-ratio"] {
        position: relative;
    }
    [style*="--aspect-ratio"]::before {
        content: "";
        display: block;
        padding-bottom: 100%;
    }
    [style*="--aspect-ratio"] > :first-child {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
    }
}

//
// Aspect ratio
//

// Prevent page reflow issues by utilizing the
// CSS instinct ration scaling technique
.ratio-container {
    position: relative;
}
.ratio-container::after {
    content: '';
    display: block;
    height: 0;
    width: 100%;
    content: "";
}

// Use delimiter class for alternative formats
.ratio-container-base::after {
    /* 1:1 = 100% = calc(1 / 1 * 100%) */
    padding-bottom: 100%;
}
.ratio-container-2-1::after {
    /* 2:1 = 50% = calc(1 / 2 * 100%) */
    padding-bottom: 50%;
}
.ratio-container-1-2::after {
    /* 1:2 = 200% = calc(2 / 1 * 100%) */
    padding-bottom: 200%;
}
.ratio-container-4-3::after {
    /* 4:3 = 75% = calc(3 / 4 * 100%) */
    padding-bottom: 75%;
}
.ratio-container-16-9::after {
    /* 16:9 = 56.25% = calc(9 / 16 * 100%) */
    padding-bottom: 56.25%;
}

.ratio-container > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


// Unknown ration variation
//
// Should deal gracefully
// with autocalculated image sizes
.unknown-ratio-container > * {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
}

