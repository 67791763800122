.o-brand {
    display: block;
    margin: 1rem 1rem 0 $app-spacer-base;
    width: 115px;
    height: 50px;
    @include mq($from: md) {
        width: 225px;
        height: 90px;
        margin: 1rem 1.5rem 0 $app-spacer-base;
    }
    @include mq($from: lg) {
        margin-left: 0;
    }
}
