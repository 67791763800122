//
// Horizontal rules
//

hr {
    margin-top: $app-hr-margin;
    margin-bottom: $app-hr-margin;
    border: 0;
    border-top: $app-hr-border-width solid $app-hr-border-color;
}
