//
// CSS animations
// ----------------------------------------------

.animated,
.u-animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

// Fades
// ----------------------------------------
@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.fadeIn {
    animation-name: fadeIn;
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

.fadeOut {
    animation-name: fadeOut;
}

@keyframes fadeInDown {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.fadeInDown {
    animation-name: fadeInDown;
}

@keyframes fadeOutUp {
    0% {
        opacity: 1;
        transform: translateY(0);
    }

    100% {
        opacity: 0;
        transform: translateY(-20px);
    }
}

.fadeOutUp {
    animation-name: fadeOutUp;
}

@keyframes fadeInRight {
    from {
        opacity: 0;
        transform: translate3d(100%, 0, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

.fadeInRight {
    animation-name: fadeInRight;
}


// Dimmer to gradually fade or dim in areas
// ----------------------------------------

// Mixin class to construct vendor prefixed versions
@mixin app-frame-dimmer() {
    from { opacity:0; }
    to { opacity:1; }
}

// make keyframes that tell the start state and the end state of our object
// @-webkit-keyframes dimmer-animation {app-frame-dimmer;}
// @-moz-keyframes dimmer-animation {app-frame-dimmer;}
// @-ms-keyframes dimmer-animation {app-frame-dimmer;}
// @-o-keyframes dimmer-animation {app-frame-dimmer;}
@keyframes dimmer-animation {@include app-frame-dimmer;}

// Apply this class to animated content areas
.dim-in {
    opacity: 0;  /* make things invisible upon start */
    animation: dimmer-animation ease-in 1;
    animation-fill-mode: forwards;
    animation-duration: 1s;
    &.dim-in-1 {
        transition-delay: 0.5s;
    }
    &.dim-in-2 {
        transition-delay: 1.2s;
    }
    &.dim-in-3 {
        transition-delay: 1.8s;
    }
}

//= Global transition timer
//
//## Modifier classes to delay transitions
.app-dlay-1 {
    transition-delay: 0.5s;
}

.app-dlay-2 {
    transition-delay: 1.2s;
}

.app-dlay-3 {
    transition-delay: 1.8s;
}

// Background fade
@-o-keyframes backgroundFade {
    0%   { background-color: #FFFFFF; }
    50%  { background-color: #AD301B; }
    100% { background-color: #FFFFFF; }
}
@keyframes backgroundFade {
    0%   { background-color: #FFFFFF; }
    50%  { background-color: #AD301B; }
    100% { background-color: #FFFFFF; }
}

.backgroundAnimated{
    background-image:none !important;
    -o-animation: backgroundFade 5s ease-in-out;
    animation: backgroundFade 5s ease-in-out;
}

// Grow
@keyframes grow {
    from {
        height: 0;
    }

    to {
        height: 100%;
    }
}

.u-animation--grow {
    animation-name: grow;
}
