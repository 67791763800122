$c-list-spacer:                             $app-spacer-base;
$c-list-margin-x:                           $c-list-spacer;
$c-list-border-color:                       $app-color-text;
$c-list-border-width:                       1px;
$c-list-separator-color:                    $app-color-secondary;
$c-list-separator-margin:                   calc(#{$app-spacer-base} * 1.5);
$c-list-separator-width:                    90px;
$c-list-separator-height:                   .25rem;

.c-list {
    display: block;
    &--grid {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    &--baseline {
        align-content: baseline;
    }
    &--gutter {
        // width: 100%;
        justify-content: space-between;
        @include mq($from: lg) {
            margin-left: -($c-list-margin-x);
            margin-right: -($c-list-margin-x);
        }
    }
    &--tiles {
        margin-bottom: $app-spacer-base;
    }
}

.c-list__item {
    @include when-inside('.c-list--grid') {
        flex: 1 0 100%;
        max-width: 100%;
        @include mq($until: lg) {
            &--width-25 {
                flex: 1 0 50%;
                max-width: 50%;
            }
        }
        @include mq($from: lg) {
            flex: 1 0 33.3333%;
            max-width: 33.3333%;
            &--width-25 {
                flex: 1 0 25%;
                max-width: 25%;
            }
        }
    }

    @include when-inside('.c-widget--hph-teaser-links-external') {
        flex: 1 0 100%;
        max-width: 100%;
        @include mq($from: md) {
            flex: 1 0 33.3333%;
            max-width: 33.3333%;
        }
        @include mq($from: lg) {
            flex: 1 0 33.3333%;
            max-width: 33.3333%;
        }
    }

    margin-bottom: calc(#{$c-list-spacer} * 1.5);
    @include when-inside('.c-list--gutter') {
        @include mq($from: lg) {
            padding-left: $c-list-margin-x;
            padding-right: $c-list-margin-x;
            &:nth-child(3n) {
                // padding-right: 0;
            }
        }
    }

    @include when-inside('.c-teaser--links') {
        @include mq($from: lg) {
            padding-left: $c-list-margin-x;
            padding-right: $c-list-margin-x;
            &:first-child {
             // padding-left: $c-list-margin-x;
            }
        }
    }

    @include when-inside('.c-list--tiles') {
        display: block;
        padding: $c-list-margin-x;
        margin-bottom: 0;
        border: $c-list-border-width solid $c-list-border-color;
        border-top-width: 0;
        // border-width: 0 $c-list-border-width $c-list-border-width $c-list-border-width;
        color: $app-color-text;
        &:first-child {
            border-top-width: $c-list-border-width;
        }
    }
    @include when-inside('c-list--bordered') {
        border-bottom: 1px solid $c-list-border-color;
        &:last-of-type {
            border-bottom: 0px;
        }
    }
}

.c-list__item--card {
    position: relative;
    &:not(:last-child) {
        &::after {
            content: "";
            position: absolute;
            @include mq($until: lg) {
                bottom: -(calc(#{$c-list-separator-margin} / 2));
                left: 0;
                width: $c-list-separator-width;
                height: $c-list-separator-height;
                background-color: $c-list-separator-color;
            }
            @include mq($from: lg) {
                top: 0;
                right: 0;
                width: 1px;
                height: 100%;
                background-color: $c-list-border-color;
            }
        }
    }
    @include when-inside('.c-teaser--links') {
        &::after {
            display: none;
        }
    }

    @include mq($from: lg) {
        &:not(:nth-child(3n+3)) {
            // border-right: 1px solid $c-list-border-color;
        }
    }
}

.c-list__item--action {
    color: lighten($app-color-text, 20%);
    &:hover {
        text-decoration: none;
        background-color: $app-color-gray-light;
        color: $app-color-text;
    }
}


.c-card-list {

}

.c-card-list__item {
    @include when-inside('.c-card-list') {
        padding-right: $app-spacer-base;
        &:not(:first-of-type) {
            padding-left: $app-spacer-base;
        }
        &:not(last) {
            @include mq($from: md) {
                border-right: 1px solid $app-color-gray;
            }
        }
        &:last-of-type {
            border-right: 0;
        }
    }
}
