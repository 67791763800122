@charset "UTF-8";
.animated,
.u-animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeIn {
  animation-name: fadeIn;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInDown {
  animation-name: fadeInDown;
}

@keyframes fadeOutUp {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}

.fadeOutUp {
  animation-name: fadeOutUp;
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInRight {
  animation-name: fadeInRight;
}

@keyframes dimmer-animation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.dim-in {
  opacity: 0;
  /* make things invisible upon start */
  animation: dimmer-animation ease-in 1;
  animation-fill-mode: forwards;
  animation-duration: 1s;
}

.dim-in.dim-in-1 {
  transition-delay: 0.5s;
}

.dim-in.dim-in-2 {
  transition-delay: 1.2s;
}

.dim-in.dim-in-3 {
  transition-delay: 1.8s;
}

.app-dlay-1 {
  transition-delay: 0.5s;
}

.app-dlay-2 {
  transition-delay: 1.2s;
}

.app-dlay-3 {
  transition-delay: 1.8s;
}

@-o-keyframes backgroundFade {
  0% {
    background-color: #FFFFFF;
  }
  50% {
    background-color: #AD301B;
  }
  100% {
    background-color: #FFFFFF;
  }
}

@keyframes backgroundFade {
  0% {
    background-color: #FFFFFF;
  }
  50% {
    background-color: #AD301B;
  }
  100% {
    background-color: #FFFFFF;
  }
}

.backgroundAnimated {
  background-image: none !important;
  -o-animation: backgroundFade 5s ease-in-out;
  animation: backgroundFade 5s ease-in-out;
}

@keyframes grow {
  from {
    height: 0;
  }
  to {
    height: 100%;
  }
}

.u-animation--grow {
  animation-name: grow;
}

/* Taz-SemiLight & Taz-SemiBold */
@font-face {
  font-family: 'Taz-SemiLight';
  src: url("../assets/fonts/TazWt04-SemiLight.woff2") format("woff2"), url("../assets/fonts/TazWt04-SemiLight.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Taz-SemiBold';
  src: url("../assets/fonts/TazWt06-SemiBold.woff2") format("woff2"), url("../assets/fonts/TazWt06-SemiBold.woff") format("woff");
  font-weight: normal;
  font-style: bold;
}

.hll {
  background-color: #ffffcc;
}

.c {
  color: #999;
}

.err {
  color: #AA0000;
  background-color: #FFAAAA;
}

.k {
  color: #006699;
}

.o {
  color: #555555;
}

.cm {
  color: #999;
}

.cp {
  color: #009999;
}

.c1 {
  color: #999;
}

.cs {
  color: #999;
}

.gd {
  background-color: #FFCCCC;
  border: 1px solid #CC0000;
}

.ge {
  font-style: italic;
}

.gr {
  color: #FF0000;
}

.gh {
  color: #003300;
}

.gi {
  background-color: #CCFFCC;
  border: 1px solid #00CC00;
}

.go {
  color: #AAAAAA;
}

.gp {
  color: #000099;
}

.gu {
  color: #003300;
}

.gt {
  color: #99CC66;
}

.kc {
  color: #006699;
}

.kd {
  color: #006699;
}

.kn {
  color: #006699;
}

.kp {
  color: #006699;
}

.kr {
  color: #006699;
}

.kt {
  color: #007788;
}

.m {
  color: #FF6600;
}

.s {
  color: #d44950;
}

.na {
  color: #4f9fcf;
}

.nb {
  color: #336666;
}

.nc {
  color: #00AA88;
}

.no {
  color: #336600;
}

.nd {
  color: #9999FF;
}

.ni {
  color: #999999;
}

.ne {
  color: #CC0000;
}

.nf {
  color: #CC00FF;
}

.nl {
  color: #9999FF;
}

.nn {
  color: #00CCFF;
}

.nt {
  color: #2f6f9f;
}

.nv {
  color: #003333;
}

.ow {
  color: #000000;
}

.w {
  color: #bbbbbb;
}

.mf {
  color: #FF6600;
}

.mh {
  color: #FF6600;
}

.mi {
  color: #FF6600;
}

.mo {
  color: #FF6600;
}

.sb {
  color: #CC3300;
}

.sc {
  color: #CC3300;
}

.sd {
  color: #CC3300;
  font-style: italic;
}

.s2 {
  color: #CC3300;
}

.se {
  color: #CC3300;
}

.sh {
  color: #CC3300;
}

.si {
  color: #AA0000;
}

.sx {
  color: #CC3300;
}

.sr {
  color: #33AAAA;
}

.s1 {
  color: #CC3300;
}

.ss {
  color: #FFCC33;
}

.bp {
  color: #336666;
}

.vc {
  color: #003333;
}

.vg {
  color: #003333;
}

.vi {
  color: #003333;
}

.il {
  color: #FF6600;
}

.css .o,
.css .o + .nt,
.css .nt + .nt {
  color: #999;
}

[style*="--aspect-ratio"] > :first-child {
  width: 100%;
}

[style*="--aspect-ratio"] > img {
  height: auto;
}

@supports (--custom: property) {
  [style*="--aspect-ratio"] {
    position: relative;
  }
  [style*="--aspect-ratio"]::before {
    content: "";
    display: block;
    padding-bottom: calc(100% / (var(--aspect-ratio)));
  }
  [style*="--aspect-ratio"] > :first-child {
    position: absolute;
    top: 0;
    left: 0;
    height: auto;
  }
}

@supports not (--custom: property) {
  [style*="--aspect-ratio"] {
    position: relative;
  }
  [style*="--aspect-ratio"]::before {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
  [style*="--aspect-ratio"] > :first-child {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }
}

.ratio-container {
  position: relative;
}

.ratio-container::after {
  content: '';
  display: block;
  height: 0;
  width: 100%;
  content: "";
}

.ratio-container-base::after {
  /* 1:1 = 100% = calc(1 / 1 * 100%) */
  padding-bottom: 100%;
}

.ratio-container-2-1::after {
  /* 2:1 = 50% = calc(1 / 2 * 100%) */
  padding-bottom: 50%;
}

.ratio-container-1-2::after {
  /* 1:2 = 200% = calc(2 / 1 * 100%) */
  padding-bottom: 200%;
}

.ratio-container-4-3::after {
  /* 4:3 = 75% = calc(3 / 4 * 100%) */
  padding-bottom: 75%;
}

.ratio-container-16-9::after {
  /* 16:9 = 56.25% = calc(9 / 16 * 100%) */
  padding-bottom: 56.25%;
}

.ratio-container > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.unknown-ratio-container > * {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}

:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #182f7a;
  --secondary: #eed343;
  --danger: #900707;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Arial Narrow", Arial;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "adobe-garamond-pro", "adobe-garamond-pro", Garamont, "Times New Roman", Times, serif;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #182f7a;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

@media (max-width: 1200px) {
  legend {
    font-size: calc(1.275rem + 0.3vw) ;
  }
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: "Taz-SemiBold", "Arial Narrow", Arial;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.5rem;
}

@media (max-width: 1200px) {
  h1, .h1 {
    font-size: calc(1.375rem + 1.5vw) ;
  }
}

h2, .h2 {
  font-size: 2rem;
}

@media (max-width: 1200px) {
  h2, .h2 {
    font-size: calc(1.325rem + 0.9vw) ;
  }
}

h3, .h3 {
  font-size: 1.75rem;
}

@media (max-width: 1200px) {
  h3, .h3 {
    font-size: calc(1.3rem + 0.6vw) ;
  }
}

h4, .h4 {
  font-size: 1.5rem;
}

@media (max-width: 1200px) {
  h4, .h4 {
    font-size: calc(1.275rem + 0.3vw) ;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

@media (max-width: 1200px) {
  .display-1 {
    font-size: calc(1.725rem + 5.7vw) ;
  }
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

@media (max-width: 1200px) {
  .display-2 {
    font-size: calc(1.675rem + 5.1vw) ;
  }
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

@media (max-width: 1200px) {
  .display-3 {
    font-size: calc(1.575rem + 3.9vw) ;
  }
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

@media (max-width: 1200px) {
  .display-4 {
    font-size: calc(1.475rem + 2.7vw) ;
  }
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 0.875em;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 0.875em;
  color: #6c757d;
}

.blockquote-footer::before {
  content: "\2014\00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}

.col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}

.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}

.col-11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #bec5da;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #8793ba;
}

.table-hover .table-primary:hover {
  background-color: #aeb7d1;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #aeb7d1;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #faf3ca;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #f6e89d;
}

.table-hover .table-secondary:hover {
  background-color: #f8eeb3;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #f8eeb3;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #e0baba;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #c57e7e;
}

.table-hover .table-danger:hover {
  background-color: #d8a8a8;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #d8a8a8;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  appearance: none;
}

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
}

.form-row > .col > .valid-tooltip,
.form-row > [class*="col-"] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated select.form-control:valid, select.form-control.is-valid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}

.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
}

.form-row > .col > .invalid-tooltip,
.form-row > [class*="col-"] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated select.form-control:invalid, select.form-control.is-invalid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}

.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #212529;
  text-decoration: none;
}

.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.btn.disabled, .btn:disabled {
  opacity: 0.65;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #182f7a;
  border-color: #182f7a;
}

.btn-primary:hover {
  color: #fff;
  background-color: #12235a;
  border-color: #101f4f;
}

.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #12235a;
  border-color: #101f4f;
  box-shadow: 0 0 0 0.2rem rgba(59, 78, 142, 0.5);
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #182f7a;
  border-color: #182f7a;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #101f4f;
  border-color: #0e1a45;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(59, 78, 142, 0.5);
}

.btn-secondary {
  color: #212529;
  background-color: #eed343;
  border-color: #eed343;
}

.btn-secondary:hover {
  color: #212529;
  background-color: #ebcb20;
  border-color: #e9c715;
}

.btn-secondary:focus, .btn-secondary.focus {
  color: #212529;
  background-color: #ebcb20;
  border-color: #e9c715;
  box-shadow: 0 0 0 0.2rem rgba(207, 185, 63, 0.5);
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #212529;
  background-color: #eed343;
  border-color: #eed343;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #212529;
  background-color: #e9c715;
  border-color: #ddbd14;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(207, 185, 63, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #900707;
  border-color: #900707;
}

.btn-danger:hover {
  color: #fff;
  background-color: #6c0505;
  border-color: #5f0505;
}

.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #6c0505;
  border-color: #5f0505;
  box-shadow: 0 0 0 0.2rem rgba(161, 44, 44, 0.5);
}

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #900707;
  border-color: #900707;
}

.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #5f0505;
  border-color: #530404;
}

.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(161, 44, 44, 0.5);
}

.btn-outline-primary {
  color: #182f7a;
  border-color: #182f7a;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #182f7a;
  border-color: #182f7a;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(24, 47, 122, 0.5);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #182f7a;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #182f7a;
  border-color: #182f7a;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(24, 47, 122, 0.5);
}

.btn-outline-secondary {
  color: #eed343;
  border-color: #eed343;
}

.btn-outline-secondary:hover {
  color: #212529;
  background-color: #eed343;
  border-color: #eed343;
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(238, 211, 67, 0.5);
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #eed343;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #212529;
  background-color: #eed343;
  border-color: #eed343;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(238, 211, 67, 0.5);
}

.btn-outline-danger {
  color: #900707;
  border-color: #900707;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #900707;
  border-color: #900707;
}

.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(144, 7, 7, 0.5);
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #900707;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #900707;
  border-color: #900707;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(144, 7, 7, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #182f7a;
  text-decoration: none;
}

.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
}

.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}

.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.collapsing.width {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing.width {
    transition: none;
  }
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}

.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}

.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}

.btn-group-toggle > .btn input[type="radio"],
.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}

.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}

.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}

.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group > .custom-file {
  display: flex;
  align-items: center;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}

.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}

.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
}

.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.875rem;
  padding-left: 1.5rem;
  print-color-adjust: exact;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.4375rem;
  opacity: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #007bff;
  background-color: #007bff;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #80bdff;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
  border-color: #b3d7ff;
}

.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}

.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}

.custom-control-label::before {
  position: absolute;
  top: 0.4375rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: 1px solid #adb5bd;
}

.custom-control-label::after {
  position: absolute;
  top: 0.4375rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50% / 50% 50% no-repeat;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}

.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}

.custom-switch .custom-control-label::after {
  top: calc(0.4375rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}

.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0;
  appearance: none;
}

.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}

.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}

.custom-select::-ms-expand {
  display: none;
}

.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-file-input[disabled] ~ .custom-file-label,
.custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}

.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}

.custom-range:focus {
  outline: 0;
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #007bff;
  border: 0;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}

.custom-range::-webkit-slider-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #007bff;
  border: 0;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}

.custom-range::-moz-range-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #007bff;
  border: 0;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}

.custom-range::-ms-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}

.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
}

.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
}

.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}

.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-moz-range-track {
  cursor: default;
}

.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #6c757d;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #182f7a !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #101f4f !important;
}

.bg-secondary {
  background-color: #eed343 !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #e9c715 !important;
}

.bg-danger {
  background-color: #900707 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #5f0505 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #182f7a !important;
}

.border-secondary {
  border-color: #eed343 !important;
}

.border-danger {
  border-color: #900707 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #182f7a !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0b163a !important;
}

.text-secondary {
  color: #eed343 !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #d2b313 !important;
}

.text-danger {
  color: #900707 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #470303 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #dee2e6;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}

/* Dropdown container
-------------------------------------------------------------- */
.dropmic {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.dropmic * {
  box-sizing: border-box;
}

/* Dropdown menu
-------------------------------------------------------------- */
.dropmic-menu {
  position: absolute;
  z-index: 1;
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  min-width: 10vw;
  max-width: 50vw;
  max-height: 90vh;
  width: max-content;
}

@media (max-width: 420px) {
  .dropmic-menu {
    max-width: 100vw;
  }
}

.dropmic--show .dropmic-menu {
  clip: inherit;
}

.dropmic-menu,
[data-dropmic-direction="bottom-right"] .dropmic-menu {
  top: auto;
  right: auto;
  bottom: 0;
  left: 30px;
  transform: translateY(calc(100% + 10px));
}

@media (min-width: 62em) {
  .dropmic-menu,
  [data-dropmic-direction="bottom-right"] .dropmic-menu {
    left: 0;
  }
}

[data-dropmic-direction="bottom-left"] .dropmic-menu {
  top: auto;
  right: 0;
  bottom: 0;
  left: auto;
  transform: translateY(calc(100% + 10px));
}

[data-dropmic-direction="top-left"] .dropmic-menu {
  top: 0;
  right: 0;
  bottom: auto;
  left: auto;
  transform: translateY(calc(-100% - 10px));
}

[data-dropmic-direction="top-right"] .dropmic-menu {
  top: 0;
  right: auto;
  bottom: auto;
  left: 0;
  transform: translateY(calc(-100% - 10px));
}

[data-dropmic-direction="bottom-middle"] .dropmic-menu {
  top: auto;
  right: auto;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%) translateY(calc(100% + 10px));
}

[data-dropmic-direction="top-middle"] .dropmic-menu {
  top: 0;
  right: auto;
  bottom: auto;
  left: 50%;
  transform: translateX(-50%) translateY(calc(-100% - 10px));
}

/* arrow */
.dropmic::before {
  position: absolute;
  width: 0;
  height: 0;
  background-size: 100% 100%;
  content: "";
}

.dropmic::before,
.dropmic::after {
  position: absolute;
  z-index: 1;
  display: none;
  width: 0;
  height: 0;
  background-size: 100% 100%;
  content: "";
}

.dropmic--show::before,
.dropmic--show::after {
  display: none;
}

.dropmic::before,
[data-dropmic-direction="bottom-left"]::before,
[data-dropmic-direction="bottom-right"]::before,
[data-dropmic-direction="bottom-middle"]::before {
  top: calc(100% + 10px);
  border: 7px solid transparent;
  border-bottom-color: #d6d5d5;
  transform: translateY(-100%);
}

.dropmic::after,
[data-dropmic-direction="bottom-left"]::after,
[data-dropmic-direction="bottom-right"]::after,
[data-dropmic-direction="bottom-middle"]::after {
  top: calc(100% + 11px);
  border: 7px solid transparent;
  border-bottom-color: #fff;
  transform: translateY(-100%);
}

.dropmic::before,
.dropmic::after,
[data-dropmic-direction="bottom-right"]::before,
[data-dropmic-direction="bottom-right"]::after {
  right: calc(50% - 4px);
}

[data-dropmic-direction="bottom-left"]::before,
[data-dropmic-direction="bottom-left"]::after {
  right: calc(50% - 4px);
}

[data-dropmic-direction="bottom-middle"]::before,
[data-dropmic-direction="bottom-middle"]::after {
  right: auto;
  left: calc(50% - 3px);
  transform: translateX(-50%) translateY(-100%);
}

[data-dropmic-direction="top-left"]::before,
[data-dropmic-direction="top-right"]::before,
[data-dropmic-direction="top-middle"]::before {
  top: -24px;
  bottom: -1px;
  border: 7px solid transparent;
  border-top-color: #d6d5d5;
  transform: translateY(100%);
}

[data-dropmic-direction="top-left"]::after,
[data-dropmic-direction="top-right"]::after,
[data-dropmic-direction="top-middle"]::after {
  top: -25px;
  bottom: 0;
  border: 7px solid transparent;
  border-top-color: #fff;
  transform: translateY(100%);
}

[data-dropmic-direction="top-left"]::before,
[data-dropmic-direction="top-left"]::after {
  right: calc(50% - 5px);
  left: auto;
}

[data-dropmic-direction="top-right"]::before,
[data-dropmic-direction="top-right"]::after {
  right: auto;
  left: calc(50% - 7px);
}

[data-dropmic-direction="top-middle"]::before,
[data-dropmic-direction="top-middle"]::after {
  right: auto;
  left: 50%;
  transform: translateX(-50%) translateY(100%);
}

/* Dropdown menu content
-------------------------------------------------------------- */
/* Content style */
.dropmic-menu__custom,
.dropmic-menu__listContent,
.dropmic-menu__listContent:link,
.dropmic-menu__listContent:visited {
  display: block;
  overflow: hidden;
  width: 100%;
  border: none;
  border-radius: 0;
  background: none;
  color: #373737;
  text-align: left;
  text-decoration: none;
  text-overflow: ellipsis;
  font-size: 1em;
  font-family: "Open Sans", "Source Sans Pro", Tahoma, sans-serif;
  cursor: pointer;
}

.dropmic-menu__custom {
  padding: 15px 30px;
  cursor: inherit;
}

.dropmic-menu__list,
.dropmic-menu__listItem {
  margin: 0;
  padding: 0;
  list-style: none;
}

.dropmic-menu__listContent {
  padding: 10px 30px;
}

/* When list come after custom content */
.dropmic-menu__listContent:nth-child(n) {
  border-top: 1px solid #f2f2f2;
}

/* When list come first */
.dropmic-menu__list:first-child .dropmic-menu__listContent {
  border-top: none;
  border-bottom: 1px solid #f2f2f2;
}

/* When the are only list items¨*/
.dropmic-menu__list:only-child .dropmic-menu__listItem:last-child .dropmic-menu__listContent {
  border-bottom: none;
}

/* States */
.dropmic-menu__listContent:hover,
.dropmic-menu__listContent:active {
  background-color: #f2f2f2;
  color: #000;
}

.c-password-toggle {
  padding-top: .25rem;
  text-align: right;
  font-size: 1rem;
}

/*	Hide show/hide labels by default */
.x-ray,
.x-ray-show,
.x-ray-hide {
  display: none;
  visibility: hidden;
}

/*	Display show/hide toggle when modern JS API's supported.
 *	Display show/hide labels when active. */
.js-x-ray .x-ray,
.x-ray-show.active,
.x-ray-hide.active {
  display: inline;
  visibility: visible;
}

/*===============================
=            Choices            =
===============================*/
.choices {
  position: relative;
  margin-bottom: calc(1.25rem * 1.5);
  font-size: 1.25rem;
  font-family: "Taz-SemiBold";
}

.choices:focus {
  outline: none;
}

.choices:last-child {
  margin-bottom: 0;
}

.choices.is-disabled .choices__inner,
.choices.is-disabled .choices__input {
  background-color: #efefef;
  cursor: not-allowed;
  user-select: none;
}

.choices.is-disabled .choices__item {
  cursor: not-allowed;
}

.choices[data-type*="select-one"] {
  cursor: pointer;
}

.choices[data-type*="select-one"] .choices__inner {
  padding-bottom: 7.5px;
}

.choices[data-type*="select-one"] .choices__input {
  display: block;
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid #DDDDDD;
  background-color: #FFFFFF;
  margin: 0;
}

.choices[data-type*="select-one"] .choices__button {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjMDAwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==);
  padding: 0;
  background-size: 8px;
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -10px;
  margin-right: 25px;
  height: 20px;
  width: 20px;
  border-radius: 10em;
  opacity: .5;
}

.choices[data-type*="select-one"] .choices__button:hover, .choices[data-type*="select-one"] .choices__button:focus {
  opacity: 1;
}

.choices[data-type*="select-one"] .choices__button:focus {
  box-shadow: 0px 0px 0px 2px #182f7a;
}

.choices[data-type*="select-one"]:after {
  content: "";
  height: 0;
  width: 0;
  border-style: solid;
  border-color: #212529 transparent transparent transparent;
  border-width: 5px;
  position: absolute;
  right: 11.5px;
  top: 50%;
  margin-top: -2.5px;
  pointer-events: none;
}

.choices[data-type*="select-one"].is-open:after {
  border-color: transparent transparent #212529 transparent;
  margin-top: -7.5px;
}

.choices[data-type*="select-one"][dir="rtl"]:after {
  left: 11.5px;
  right: auto;
}

.choices[data-type*="select-one"][dir="rtl"] .choices__button {
  right: auto;
  left: 0;
  margin-left: 25px;
  margin-right: 0;
}

.choices[data-type*="select-multiple"] .choices__inner,
.choices[data-type*="text"] .choices__inner {
  cursor: text;
}

.choices[data-type*="select-multiple"] .choices__button,
.choices[data-type*="text"] .choices__button {
  position: relative;
  display: inline-block;
  margin-top: 0;
  margin-right: -4px;
  margin-bottom: 0;
  margin-left: 8px;
  padding-left: 16px;
  border-left: 1px solid #101f4f;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjRkZGIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==);
  background-size: 8px;
  width: 8px;
  line-height: 1;
  opacity: .75;
  border-radius: 0;
}

.choices[data-type*="select-multiple"] .choices__button:hover, .choices[data-type*="select-multiple"] .choices__button:focus,
.choices[data-type*="text"] .choices__button:hover,
.choices[data-type*="text"] .choices__button:focus {
  opacity: 1;
}

.choices__inner {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  background-color: #fff;
  padding: 7.5px 7.5px 3.75px;
  border: 1px solid #DDDDDD;
  border-radius: 0;
  font-size: 1rem;
  min-height: 44px;
  overflow: hidden;
}

.is-focused .choices__inner,
.is-open .choices__inner {
  border-color: #b7b7b7;
}

.is-open .choices__inner {
  border-radius: 0 0 0 0;
}

.is-flipped.is-open .choices__inner {
  border-radius: 0 0 0 0;
}

.choices__list {
  margin: 0;
  padding-left: 0;
  list-style: none;
}

.choices__list--single {
  display: inline-block;
  padding: 4px 16px 4px 4px;
  width: 100%;
}

[dir="rtl"] .choices__list--single {
  padding-right: 4px;
  padding-left: 16px;
}

.choices__list--single .choices__item {
  width: 100%;
}

.choices__list--multiple {
  display: inline;
}

.choices__list--multiple .choices__item {
  display: inline-block;
  vertical-align: middle;
  border-radius: calc(1.25rem * 1.5);
  padding: 4px 10px;
  font-size: 1rem;
  font-weight: 500;
  margin-right: 3.75px;
  margin-bottom: 3.75px;
  background-color: #182f7a;
  border: 1px solid #142765;
  color: #FFFFFF;
  word-break: break-all;
}

.choices__list--multiple .choices__item[data-deletable] {
  padding-right: 5px;
}

[dir="rtl"] .choices__list--multiple .choices__item {
  margin-right: 0;
  margin-left: 3.75px;
}

.choices__list--multiple .choices__item.is-highlighted {
  background-color: #142765;
  border: 1px solid #101f4f;
}

.is-disabled .choices__list--multiple .choices__item {
  background-color: #aaaaaa;
  border: 1px solid #919191;
}

.choices__list--dropdown {
  display: none;
  z-index: 1;
  position: absolute;
  width: 100%;
  background-color: #FFFFFF;
  border: 1px solid #DDDDDD;
  top: 100%;
  margin-top: -1px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  overflow: hidden;
  word-break: break-all;
}

.choices__list--dropdown.is-active {
  display: block;
}

.is-open .choices__list--dropdown {
  border-color: #b7b7b7;
}

.is-flipped .choices__list--dropdown {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: -1px;
  border-radius: .25rem .25rem 0 0;
}

.choices__list--dropdown .choices__list {
  position: relative;
  max-height: 300px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  will-change: scroll-position;
}

.choices__list--dropdown .choices__item {
  position: relative;
  padding: 10px;
  font-size: 1rem;
}

[dir="rtl"] .choices__list--dropdown .choices__item {
  text-align: right;
}

@media (min-width: 640px) {
  .choices__list--dropdown .choices__item--selectable {
    padding-right: 100px;
  }
  .choices__list--dropdown .choices__item--selectable:after {
    content: attr(data-select-text);
    font-size: 1rem;
    opacity: 0;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
  [dir="rtl"] .choices__list--dropdown .choices__item--selectable {
    text-align: right;
    padding-left: 100px;
    padding-right: 10px;
  }
  [dir="rtl"] .choices__list--dropdown .choices__item--selectable:after {
    right: auto;
    left: 10px;
  }
}

.choices__list--dropdown .choices__item--selectable.is-highlighted {
  background-color: #f2f2f2;
}

.choices__list--dropdown .choices__item--selectable.is-highlighted:after {
  opacity: .5;
}

.choices__item {
  cursor: default;
}

.choices__item--selectable {
  cursor: pointer;
}

.choices__item--disabled {
  cursor: not-allowed;
  user-select: none;
  opacity: .5;
}

.choices__heading {
  font-weight: 600;
  font-size: 1rem;
  padding: 10px;
  border-bottom: 1px solid #f7f6f6;
  color: #807f7f;
}

.choices__button {
  text-indent: -9999px;
  -webkit-appearance: none;
  appearance: none;
  border: 0;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.choices__button:focus {
  outline: none;
}

.choices__input {
  display: inline-block;
  vertical-align: baseline;
  background-color: #fff;
  font-size: 1.25rem;
  margin-bottom: 5px;
  border: 0;
  border-radius: 0;
  max-width: 100%;
  padding: 4px 0 4px 2px;
}

.choices__input:focus {
  outline: 0;
}

[dir="rtl"] .choices__input {
  padding-right: 2px;
  padding-left: 0;
}

.choices__placeholder {
  opacity: .5;
}

.choices__input.is-hidden,
.choices[data-type*="select-one"] .choices__input.is-hidden,
.choices[data-type*="select-multiple"] .choices__input.is-hidden {
  display: none;
}

/*=====  End of Choices  ======*/
/*! Flickity v2.2.1
https://flickity.metafizzy.co
---------------------------------------------- */
.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus {
  outline: none;
}

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* draggable */
.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

/* ---- flickity-button ---- */
.flickity-button {
  position: absolute;
  background: rgba(255, 255, 255, 0.75);
  border: none;
  color: #333;
}

.flickity-button:hover {
  background: white;
  cursor: pointer;
}

.flickity-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #19F;
}

.flickity-button:active {
  opacity: 0.6;
}

.flickity-button:disabled {
  opacity: 0.3;
  cursor: auto;
  /* prevent disabled button from capturing pointer up event. #716 */
  pointer-events: none;
}

.flickity-button-icon {
  fill: currentColor;
}

/* ---- previous/next buttons ---- */
.flickity-prev-next-button {
  top: 50%;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  /* vertically center */
  transform: translateY(-50%);
}

.flickity-prev-next-button.previous {
  left: 10px;
}

.flickity-prev-next-button.next {
  right: 10px;
}

/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px;
}

.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px;
}

.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%;
}

/* ---- page dots ---- */
.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;
}

.flickity-rtl .flickity-page-dots {
  direction: rtl;
}

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer;
}

.flickity-page-dots .dot.is-selected {
  opacity: 1;
}

.actionMenu {
  display: none;
}

.searchPage .input-group {
  margin-bottom: 1rem;
}

.searchResults {
  padding-left: 1rem;
}

.searchResults > li {
  border-bottom: 1px solid #ccc;
  padding-top: 1rem;
  padding-bottom: 1rem;
  list-style-position: outside;
}

.searchResults > li > cite {
  display: block;
  font-size: 0.875rem;
}

.searchResults > li .discreet {
  display: none;
}

.searchResults .result-title {
  font-family: "Taz-SemiBold";
  font-weight: 500;
  font-size: 1.3rem;
}

html {
  overflow-y: scroll;
  /* [2] */
  min-height: 100%;
  /* [3] */
  color: #212529;
  width: 100%;
  font-family: "adobe-garamond-pro";
  font-weight: 300;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  color: #212529;
  font-size: 1.25rem;
  font-weight: 400;
}

.fonts--loaded body {
  font-weight: 400;
}

body.u-no-scroll {
  /* disable scrollbar on both x and y axis */
  overflow: hidden;
  /* disable scroll */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* OPTIONAL: none of dom element will be click-able */
  pointer-events: none;
}

body:not(.mce-content-body) {
  background-color: #fff;
}

body.mce-content-body {
  padding: 1rem !important;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(33, 37, 41, 0.1);
}

b,
strong {
  font-weight: 700;
}

.font__primary--loaded b, .font__primary--loaded
strong {
  font-weight: 700;
}

button {
  appearance: none;
  background: transparent;
}

a:not(class) {
  color: #182f7a;
}

a:not(class):hover {
  color: #0b163a;
  transition: color 0.5s linear;
}

.o-page__body-text a:not(class) {
  font-weight: 600;
}

img {
  max-width: 100%;
  object-fit: cover;
}

img[data-sizes="auto"] {
  display: block;
  width: 100%;
}

.o-image--lazy-load {
  opacity: 0;
  transition: opacity 200ms;
}

.o-image--loaded {
  opacity: 1;
}

.o-image--inline {
  margin-bottom: calc(1.25rem * 1.5);
  margin-right: calc(1.25rem * 1.5);
}

p {
  margin-bottom: 1.25rem;
}

.c-snippet__section p {
  margin-bottom: 1rem;
}

p.u-font-test {
  font-family: Trebuchet !important;
}

ul {
  margin-bottom: calc(1.25rem * 1.5);
  padding-left: 1rem;
}

@media (min-width: 48em) {
  ul {
    padding-left: calc(1.25rem * 2);
  }
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: "Taz-SemiBold", "Arial Narrow", Arial;
  font-weight: 500;
  line-height: 1.2;
}

h3, h4, h5, h6,
.h3, .h4, .h5, .h6 {
  font-family: "Taz-SemiLight";
}

h1, .h1 {
  font-size: 2.5rem;
}

@media (max-width: 1200px) {
  h1, .h1 {
    font-size: calc(1.375rem + 1.5vw) ;
  }
}

h2, .h2 {
  font-size: 2rem;
}

@media (max-width: 1200px) {
  h2, .h2 {
    font-size: calc(1.325rem + 0.9vw) ;
  }
}

h3, .h3 {
  font-size: 1.75rem;
}

@media (max-width: 1200px) {
  h3, .h3 {
    font-size: calc(1.3rem + 0.6vw) ;
  }
}

h4, .h4 {
  font-size: 1.5rem;
}

@media (max-width: 1200px) {
  h4, .h4 {
    font-size: calc(1.275rem + 0.3vw) ;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

table,
.o-table {
  width: 100%;
  margin-bottom: calc(1.5 * $app-font-size-base);
  color: #212529;
  background-color: #fff;
}

table th,
table td,
.o-table th,
.o-table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #efefef;
}

@media (max-width: 61.99em) {
  table th:first-child,
  table td:first-child,
  .o-table th:first-child,
  .o-table td:first-child {
    padding-left: 0;
  }
}

table thead th,
.o-table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #efefef;
}

table tbody + tbody,
.o-table tbody + tbody {
  border-top: 2px solid #efefef;
}

.o-table__container {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.o-table__container > .o-table--bordered {
  border: 0;
}

.o-table--sm th,
.o-table--sm td {
  padding: 0.3rem;
}

.o-table__row--actions {
  border-top: 0.25rem solid #212529;
  padding-top: calc(1.25rem * 1.5);
}

.o-table__cell--actions {
  width: 20%;
  padding-top: calc(1.25rem * 1.5 / 2) !important;
}

.o-table__cell--primary {
  width: 40%;
}

.o-page {
  display: block;
  position: relative;
  padding-right: calc(1.25rem * 1.5);
  padding-left: calc(1.25rem * 1.5);
  width: 100%;
}

@media (min-width: 62em) {
  .o-page {
    padding-right: 0;
    padding-left: 0;
  }
}

body:not(.portaltype-hph-sitecontent-mainsection) .o-page:not(.o-page--panels) {
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 62em) and (min-width: 992px) {
  body:not(.portaltype-hph-sitecontent-mainsection) .o-page:not(.o-page--panels) {
    max-width: 960px;
  }
}

@media (min-width: 62em) and (min-width: 1200px) {
  body:not(.portaltype-hph-sitecontent-mainsection) .o-page:not(.o-page--panels) {
    max-width: 1140px;
  }
}

body:not(.portaltype-ade25-panelpage-page) .o-page:not(.o-page--panels) {
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 62em) and (min-width: 992px) {
  body:not(.portaltype-ade25-panelpage-page) .o-page:not(.o-page--panels) {
    max-width: 960px;
  }
}

@media (min-width: 62em) and (min-width: 1200px) {
  body:not(.portaltype-ade25-panelpage-page) .o-page:not(.o-page--panels) {
    max-width: 1140px;
  }
}

.o-page--panels {
  padding-left: 0;
  padding-right: 0;
}

.userrole-authenticated .o-page--panels {
  padding-top: 4rem;
}

.o-page--sign-in {
  display: flex;
  max-width: 100% !important;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(white, #efefef);
}

.o-page__header {
  margin-bottom: calc(1.25rem * 1.5);
}

@media (min-width: 62em) {
  .o-page__header-action {
    text-align: right;
  }
}

.o-page__main {
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 992px) {
  .o-page__main {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .o-page__main {
    max-width: 1140px;
  }
}

.o-page__section {
  position: relative;
  margin-bottom: calc(1.25rem * 1.5);
}

@media (min-width: 62em) {
  .template-course-view .o-page__section {
    width: 80%;
  }
}

.o-page__section--spacer {
  margin-bottom: calc(calc(1.25rem * 1.5) * 2);
}

.o-page__headline {
  padding: .125rem 0;
  color: #000;
  line-height: 1.25;
}

.o-page__asset {
  width: 100%;
}

@media (max-width: 47.99em) {
  .o-page__asset--md {
    max-width: 50%;
  }
}

.o-page__asset--inline {
  margin-bottom: calc(1.25rem * 1.5);
}

@media (min-width: 48em) {
  .o-page__asset--inline {
    margin-right: calc(1.25rem * 1.5 / 2);
  }
}

.o-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.o-grid--baseline {
  align-content: baseline;
}

.o-grid--gutter {
  width: 100%;
}

@media (min-width: 36em) {
  .o-grid--gutter {
    margin-right: -calc(1.25rem * 1.5 / 2);
    margin-left: -calc(1.25rem * 1.5 / 2);
  }
}

@media (min-width: 62em) {
  .o-grid--reverse {
    flex-direction: row-reverse;
  }
}

@media (min-width: 36em) {
  .o-grid--gutter .o-grid__item {
    padding-right: calc(1.25rem * 1.5 / 2);
    padding-left: calc(1.25rem * 1.5 / 2);
  }
}

.o-grid__item--width-10 {
  flex: 1 0 100%;
}

.mce-content-body .o-grid__item--width-10 {
  flex: 1 0 10%;
  max-width: 10%;
}

@media (min-width: 48em) {
  .o-grid--intermediate .o-grid__item--width-10 {
    flex: 1 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 36em) {
  .o-grid--sm .o-grid__item--width-10 {
    flex: 1 0 10%;
    max-width: 10%;
  }
}

@media (min-width: 48em) {
  .o-grid--md .o-grid__item--width-10 {
    flex: 1 0 10%;
    max-width: 10%;
  }
}

@media (min-width: 62em) {
  .o-grid--lg .o-grid__item--width-10 {
    flex: 1 0 10%;
    max-width: 10%;
  }
}

@media (min-width: 75em) {
  .o-grid__item--width-10 {
    flex: 1 0 10%;
    max-width: 10%;
  }
}

.o-grid__item--width-16 {
  flex: 1 0 100%;
}

.mce-content-body .o-grid__item--width-16 {
  flex: 1 0 16.6666%;
  max-width: 16.6666%;
}

@media (min-width: 48em) {
  .o-grid--intermediate .o-grid__item--width-16 {
    flex: 1 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 36em) {
  .o-grid--sm .o-grid__item--width-16 {
    flex: 1 0 16.6666%;
    max-width: 16.6666%;
  }
}

@media (min-width: 48em) {
  .o-grid--md .o-grid__item--width-16 {
    flex: 1 0 16.6666%;
    max-width: 16.6666%;
  }
}

@media (min-width: 62em) {
  .o-grid--lg .o-grid__item--width-16 {
    flex: 1 0 16.6666%;
    max-width: 16.6666%;
  }
}

@media (min-width: 75em) {
  .o-grid__item--width-16 {
    flex: 1 0 16.6666%;
    max-width: 16.6666%;
  }
}

.o-grid__item--width-20 {
  flex: 1 0 100%;
}

.mce-content-body .o-grid__item--width-20 {
  flex: 1 0 20%;
  max-width: 20%;
}

@media (min-width: 48em) {
  .o-grid--intermediate .o-grid__item--width-20 {
    flex: 1 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 36em) {
  .o-grid--sm .o-grid__item--width-20 {
    flex: 1 0 20%;
    max-width: 20%;
  }
}

@media (min-width: 48em) {
  .o-grid--md .o-grid__item--width-20 {
    flex: 1 0 20%;
    max-width: 20%;
  }
}

@media (min-width: 62em) {
  .o-grid--lg .o-grid__item--width-20 {
    flex: 1 0 20%;
    max-width: 20%;
  }
}

@media (min-width: 75em) {
  .o-grid__item--width-20 {
    flex: 1 0 20%;
    max-width: 20%;
  }
}

.o-grid__item--width-25 {
  flex: 1 0 100%;
}

.mce-content-body .o-grid__item--width-25 {
  flex: 1 0 25%;
  max-width: 25%;
}

@media (min-width: 48em) {
  .o-grid--intermediate .o-grid__item--width-25 {
    flex: 1 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 36em) {
  .o-grid--sm .o-grid__item--width-25 {
    flex: 1 0 25%;
    max-width: 25%;
  }
}

@media (min-width: 48em) {
  .o-grid--md .o-grid__item--width-25 {
    flex: 1 0 25%;
    max-width: 25%;
  }
}

@media (min-width: 62em) {
  .o-grid--lg .o-grid__item--width-25 {
    flex: 1 0 25%;
    max-width: 25%;
  }
}

@media (min-width: 75em) {
  .o-grid__item--width-25 {
    flex: 1 0 25%;
    max-width: 25%;
  }
}

.o-grid__item--width-30 {
  flex: 1 0 100%;
}

.mce-content-body .o-grid__item--width-30 {
  flex: 1 0 30%;
  max-width: 30%;
}

@media (min-width: 48em) {
  .o-grid--intermediate .o-grid__item--width-30 {
    flex: 1 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 36em) {
  .o-grid--sm .o-grid__item--width-30 {
    flex: 1 0 30%;
    max-width: 30%;
  }
}

@media (min-width: 48em) {
  .o-grid--md .o-grid__item--width-30 {
    flex: 1 0 30%;
    max-width: 30%;
  }
}

@media (min-width: 62em) {
  .o-grid--lg .o-grid__item--width-30 {
    flex: 1 0 30%;
    max-width: 30%;
  }
}

@media (min-width: 75em) {
  .o-grid__item--width-30 {
    flex: 1 0 30%;
    max-width: 30%;
  }
}

.o-grid__item--width-33 {
  flex: 1 0 100%;
}

.mce-content-body .o-grid__item--width-33 {
  flex: 1 0 33.3333%;
  max-width: 33.3333%;
}

@media (min-width: 48em) {
  .o-grid--intermediate .o-grid__item--width-33 {
    flex: 1 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 36em) {
  .o-grid--sm .o-grid__item--width-33 {
    flex: 1 0 33.3333%;
    max-width: 33.3333%;
  }
}

@media (min-width: 48em) {
  .o-grid--md .o-grid__item--width-33 {
    flex: 1 0 33.3333%;
    max-width: 33.3333%;
  }
}

@media (min-width: 62em) {
  .o-grid--lg .o-grid__item--width-33 {
    flex: 1 0 33.3333%;
    max-width: 33.3333%;
  }
}

@media (min-width: 75em) {
  .o-grid__item--width-33 {
    flex: 1 0 33.3333%;
    max-width: 33.3333%;
  }
}

.o-grid__item--width-40 {
  flex: 1 0 100%;
}

.mce-content-body .o-grid__item--width-40 {
  flex: 1 0 40%;
  max-width: 40%;
}

@media (min-width: 48em) {
  .o-grid--intermediate .o-grid__item--width-40 {
    flex: 1 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 36em) {
  .o-grid--sm .o-grid__item--width-40 {
    flex: 1 0 40%;
    max-width: 40%;
  }
}

@media (min-width: 48em) {
  .o-grid--md .o-grid__item--width-40 {
    flex: 1 0 40%;
    max-width: 40%;
  }
}

@media (min-width: 62em) {
  .o-grid--lg .o-grid__item--width-40 {
    flex: 1 0 40%;
    max-width: 40%;
  }
}

@media (min-width: 75em) {
  .o-grid__item--width-40 {
    flex: 1 0 40%;
    max-width: 40%;
  }
}

.o-grid__item--width-50 {
  flex: 1 0 100%;
}

.mce-content-body .o-grid__item--width-50 {
  flex: 1 0 50%;
  max-width: 50%;
}

@media (min-width: 48em) {
  .o-grid--intermediate .o-grid__item--width-50 {
    flex: 1 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 36em) {
  .o-grid--sm .o-grid__item--width-50 {
    flex: 1 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 48em) {
  .o-grid--md .o-grid__item--width-50 {
    flex: 1 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 62em) {
  .o-grid--lg .o-grid__item--width-50 {
    flex: 1 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 75em) {
  .o-grid__item--width-50 {
    flex: 1 0 50%;
    max-width: 50%;
  }
}

.o-grid__item--width-60 {
  flex: 1 0 100%;
}

.mce-content-body .o-grid__item--width-60 {
  flex: 1 0 60%;
  max-width: 60%;
}

@media (min-width: 48em) {
  .o-grid--intermediate .o-grid__item--width-60 {
    flex: 1 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 36em) {
  .o-grid--sm .o-grid__item--width-60 {
    flex: 1 0 60%;
    max-width: 60%;
  }
}

@media (min-width: 48em) {
  .o-grid--md .o-grid__item--width-60 {
    flex: 1 0 60%;
    max-width: 60%;
  }
}

@media (min-width: 62em) {
  .o-grid--lg .o-grid__item--width-60 {
    flex: 1 0 60%;
    max-width: 60%;
  }
}

@media (min-width: 75em) {
  .o-grid__item--width-60 {
    flex: 1 0 60%;
    max-width: 60%;
  }
}

.o-grid__item--width-66 {
  flex: 1 0 100%;
}

.mce-content-body .o-grid__item--width-66 {
  flex: 1 0 66.6666%;
  max-width: 66.6666%;
}

@media (min-width: 48em) {
  .o-grid--intermediate .o-grid__item--width-66 {
    flex: 1 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 36em) {
  .o-grid--sm .o-grid__item--width-66 {
    flex: 1 0 66.6666%;
    max-width: 66.6666%;
  }
}

@media (min-width: 48em) {
  .o-grid--md .o-grid__item--width-66 {
    flex: 1 0 66.6666%;
    max-width: 66.6666%;
  }
}

@media (min-width: 62em) {
  .o-grid--lg .o-grid__item--width-66 {
    flex: 1 0 66.6666%;
    max-width: 66.6666%;
  }
}

@media (min-width: 75em) {
  .o-grid__item--width-66 {
    flex: 1 0 66.6666%;
    max-width: 66.6666%;
  }
}

.o-grid__item--width-75 {
  flex: 1 0 100%;
}

.mce-content-body .o-grid__item--width-75 {
  flex: 1 0 75%;
  max-width: 75%;
}

@media (min-width: 48em) {
  .o-grid--intermediate .o-grid__item--width-75 {
    flex: 1 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 36em) {
  .o-grid--sm .o-grid__item--width-75 {
    flex: 1 0 75%;
    max-width: 75%;
  }
}

@media (min-width: 48em) {
  .o-grid--md .o-grid__item--width-75 {
    flex: 1 0 75%;
    max-width: 75%;
  }
}

@media (min-width: 62em) {
  .o-grid--lg .o-grid__item--width-75 {
    flex: 1 0 75%;
    max-width: 75%;
  }
}

@media (min-width: 75em) {
  .o-grid__item--width-75 {
    flex: 1 0 75%;
    max-width: 75%;
  }
}

.o-grid__item--width-100 {
  flex: 1 0 100%;
}

.mce-content-body .o-grid__item--width-100 {
  flex: 1 0 100%;
  max-width: 100%;
}

@media (min-width: 48em) {
  .o-grid--intermediate .o-grid__item--width-100 {
    flex: 1 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 36em) {
  .o-grid--sm .o-grid__item--width-100 {
    flex: 1 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 48em) {
  .o-grid--md .o-grid__item--width-100 {
    flex: 1 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 62em) {
  .o-grid--lg .o-grid__item--width-100 {
    flex: 1 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 75em) {
  .o-grid__item--width-100 {
    flex: 1 0 100%;
    max-width: 100%;
  }
}

.o-brand {
  display: block;
  margin: 1rem 1rem 0 calc(1.25rem * 1.5);
  width: 115px;
  height: 50px;
}

@media (min-width: 48em) {
  .o-brand {
    width: 225px;
    height: 90px;
    margin: 1rem 1.5rem 0 calc(1.25rem * 1.5);
  }
}

@media (min-width: 62em) {
  .o-brand {
    margin-left: 0;
  }
}

.o-burger {
  display: block;
  margin-top: 3rem;
}

@media (min-width: 48em) {
  .o-burger {
    margin-top: 5.5rem;
  }
}

@media (min-width: 75em) {
  .o-burger {
    margin-top: 0;
  }
}

.o-burger__button {
  display: flex;
  flex-direction: row-reverse;
  line-height: 1;
  background: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  width: 100%;
  padding: 0 calc(1.25rem * 1.5) 0 0;
}

@media (min-width: 62em) {
  .o-burger__button {
    padding-right: 0;
  }
}

.o-burger__text {
  align-self: flex-end;
  font-family: "Taz-SemiBold";
  font-size: 1rem;
  color: #182f7a;
  text-transform: uppercase;
  padding-bottom: 0.25rem;
}

@media (min-width: 48em) {
  .o-burger__text {
    font-size: 1.45rem;
    padding-bottom: 0;
  }
}

.o-burger__icon {
  margin-right: .5rem;
  margin-bottom: -.25rem;
  font-size: 1rem;
  color: #182f7a;
}

@media (min-width: 48em) {
  .o-burger__icon {
    font-size: 1.5rem;
  }
}

.o-icon {
  display: inline-block;
  fill: currentColor;
}

.o-icon--default {
  width: 1rem;
  height: 1rem;
}

.o-icon--inverse {
  fill: #fff;
}

.o-icon--solid {
  background-color: #fff;
}

.o-icon--primary {
  color: #182f7a;
}

.o-icon--circle {
  border: 1px solid currentColor;
  border-radius: 50%;
}

.o-icon--spacer {
  padding: .6rem;
}

.o-icon--nav-toggle {
  fill: #fff;
  width: 4rem;
  height: 4rem;
}

.o-icon--sm {
  fill: currentColor;
  width: 1.25rem;
  height: 1.25rem;
}

.o-icon--md {
  fill: currentColor;
  width: 1.5rem;
  height: 1.5rem;
}

.o-icon--lg {
  fill: currentColor;
  width: 4rem;
  height: 4rem;
}

.o-icon--size-1 {
  fill: currentColor;
  width: 1rem;
  height: 1rem;
}

.o-icon--size-2 {
  fill: currentColor;
  width: 2rem;
  height: 2rem;
}

.o-icon--size-2-5 {
  fill: currentColor;
  width: 2.5rem;
  height: 2.5rem;
}

.o-icon--size-3 {
  fill: currentColor;
  width: 3rem;
  height: 3rem;
}

.o-icon--size-4 {
  fill: currentColor;
  width: 4rem;
  height: 4rem;
}

.o-icon--size-5 {
  fill: currentColor;
  width: 5rem;
  height: 5rem;
}

.o-icon__nav--default {
  width: 1rem;
  height: 1rem;
  color: #fff;
}

.o-icon__ui--calendar-dims {
  width: 4rem;
  height: 4rem;
  fill: #adb5bd;
}

.o-icon__ui--people-dims {
  width: 5rem;
  height: 5rem;
  fill: #adb5bd;
}

.o-icon__ui--timer-dims {
  width: 4.5rem;
  height: 4.5rem;
  fill: #adb5bd;
}

.o-icon__ui--check-dims {
  width: 4.5rem;
  height: 4.5rem;
  fill: #adb5bd;
}

.o-placeholder {
  margin: 0.5rem auto;
  padding: 1rem;
  text-align: center;
}

@media (min-width: 48em) {
  .o-placeholder {
    padding: 2rem 1rem;
    width: 66.66%;
  }
}

.o-placeholder__sign {
  width: 4rem;
  height: 4rem;
  margin: 0 auto;
  padding-top: 0.5rem;
  border-radius: 50%;
  border: 1px solid #ced4da;
  color: #ced4da;
  font-size: 2rem;
}

.o-menu {
  margin-left: 0;
  padding-left: 0;
  list-style: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'/%3E");
}

.o-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #182f7a;
  font-family: "Taz-SemiBold";
}

.o-dropdown--hidden {
  display: none;
}

.o-dropdown--active {
  display: block;
}

.o-dropdown__list {
  list-style: none;
  padding: 1rem;
  width: auto;
  min-width: 14rem;
  margin-bottom: 0;
}

.o-dropdown__list-item {
  padding: .25rem;
  line-height: 1rem;
}

.o-dropdown__list-item:not(last) {
  border-bottom: 2px solid rgba(255, 255, 255, 0.1);
}

.o-dropdown__list-item:last-of-type {
  border-bottom: 0px;
}

.o-dropdown__link {
  color: #fff;
  font-size: 1rem;
  text-transform: uppercase;
}

.o-dropdown__link:hover {
  text-decoration: none;
  color: #fff;
}

.o-dropdown__icon {
  display: inline-block;
  margin-top: 0;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
  width: 1rem;
  height: 1rem;
  line-height: 1;
  float: right;
}

input, button, select, textarea {
  font-family: "adobe-garamond-pro";
}

.o-form {
  width: 100%;
}

.o-form--panels {
  padding: calc(1.25rem * 1.5 / 2);
}

.o-form__group {
  margin-bottom: calc(1.25rem * 1.5);
}

.o-form__field {
  display: block;
}

.o-form__field--hidden {
  opacity: 0;
  display: none;
  height: 0;
}

.o-form__label {
  font-family: "Taz-SemiBold";
  font-weight: 400;
  line-height: 1.25;
}

.o-form__label--float {
  color: #432e2e;
  box-sizing: border-box;
  display: block;
  font-size: 0.65rem;
  font-weight: normal;
  line-height: 1;
  opacity: 0;
  overflow: hidden;
  padding: .5rem 1rem;
  pointer-events: none;
  position: absolute;
  text-overflow: ellipsis;
  top: 0rem;
  text-align: left;
  transform: translateY(3px);
  transition: all .2s ease-out;
  user-select: none;
  white-space: nowrap;
  width: 100%;
  z-index: 1;
}

.form__field--not-empty .o-form__label--float {
  opacity: 1;
  transform: none;
}

.o-form__group--not-empty .o-form__label--float {
  opacity: 1;
  transform: none;
}

.o-form__label--float--postfix {
  top: 0;
  opacity: 1;
  transform: none;
  padding: 0 0 0 1rem;
  font-size: 1.25rem;
  margin-bottom: .5rem;
}

.o-form__help {
  display: block;
  font-family: "adobe-garamond-pro";
  font-size: 1.25rem;
  color: #5a5f64;
}

.o-form__control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .o-form__control {
    transition: none;
  }
}

.o-form__control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.o-form__control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.o-form__control:disabled, .o-form__control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

.o-form__control--hidden {
  display: none !important;
  opacity: 0 !important;
}

.o-form__control--visible {
  display: block !important;
  opacity: 1 !important;
}

.o-form__control--textarea {
  height: auto;
}

.form__field--checkbox .option {
  display: flex;
  margin-right: 1rem;
}

.form__field--checkbox .option > label {
  margin-left: .5rem;
  font-weight: 400;
}

.o-form__control--checkbox {
  position: absolute;
  left: -9999px;
}

.listingCheckbox .o-form__control--checkbox {
  position: initial;
}

.o-form__control--checkbox + label {
  position: relative;
  padding-left: 1.95em;
  cursor: pointer;
  font-weight: 400;
}

.o-form__control--checkbox + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 1.5rem;
  height: 1.5rem;
  border: 1px solid #ced4da;
  background: #fff;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}

.o-form__control--checkbox + label:hover:before {
  border: 1px solid #ced4da !important;
}

.o-form__control--checkbox + label:after {
  content: url("../assets/images/icon-check.svg");
  position: absolute;
  top: .2rem;
  left: .2rem;
  z-index: 1;
  font-size: 1.3em;
  line-height: 0.8;
  color: #000;
  transition: all .2s;
  background-color: transparent;
}

.app-field-required .o-form__control--checkbox + label {
  margin-right: 2rem;
}

/* checked mark aspect changes */
.o-form__control--checkbox:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}

.o-form__control--checkbox:checked + label:after {
  opacity: 1;
  transform: scale(1);
}

.o-form__control--checkbox:checked + label:before {
  background-image: linear-gradient(135deg, #182f7a, #182f7a);
}

/* disabled checkbox */
.o-form__control--checkbox:disabled:not(:checked) + label:before,
.o-form__control--checkbox:disabled:checked + label:before {
  box-shadow: none;
  border-color: #bbb;
  background-color: #ddd;
}

.o-form__control--checkbox:disabled:checked + label:after {
  color: #999;
}

.o-form__control--checkbox:disabled + label {
  color: #aaa;
}

/* accessibility */
.o-form__control--checkbox:checked:focus + label:before,
.o-form__control--checkbox:not(:checked):focus + label:before {
  border: 1px solid #ced4da;
}

.o-form__actions {
  padding-top: calc(1.25rem * 1.5);
  padding-bottom: calc(1.25rem * 1.5);
}

.o-form--panels .o-form__actions {
  display: flex;
  justify-content: space-between;
}

.o-form--widget .o-form__actions {
  display: flex;
  justify-content: space-between;
}

.autotoc-section {
  padding-top: calc(1.25rem * 1.5);
}

.pat-relateditems {
  height: auto;
}

/* class applies to select element itself, not a wrapper element */
.o-select {
  display: block;
  font-size: 1.25rem;
  font-family: sans-serif;
  font-weight: 700;
  color: #444;
  line-height: 1.3;
  padding: .6em 1.4em .5em .8em;
  width: 100%;
  max-width: 100%;
  /* useful when width is set to anything other than 100% */
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
  border-radius: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  /* note: bg image below uses 2 urls. The first is an svg data uri for the arrow icon, and the second is the gradient.
        for the icon, if you want to change the color, be sure to use `%23` instead of `#`, since it's a url. You can also swap in a different svg icon or an external image reference

    */
}

.o-select:not([multiple]) {
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
}

.o-select[multiple], .o-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 1.25rem;
  background-image: none;
}

/* Hide arrow icon in IE browsers */
.o-select::-ms-expand {
  display: none;
}

/* Hover style */
.o-select:hover {
  border-color: #888;
}

/* Focus style */
.o-select:focus {
  border-color: #aaa;
  /* It'd be nice to use -webkit-focus-ring-color here but it doesn't work on box-shadow */
  box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
  box-shadow: 0 0 0 3px -moz-mac-focusring;
  color: #222;
  outline: none;
}

/* Set options to normal weight */
.o-select option {
  font-weight: normal;
}

.o-switch {
  position: relative;
  display: block;
  min-height: 1.875rem;
}

.o-switch__control {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.o-switch__label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}

.o-switch__label:before {
  content: "";
  cursor: pointer;
  text-indent: -9999px;
  width: 200px;
  height: 100px;
  background: grey;
  display: block;
  border-radius: 100px;
  position: absolute;
}

.o-switch__label:after {
  content: '';
  position: absolute;
  top: 5px;
  left: 5px;
  width: 90px;
  height: 90px;
  background: #fff;
  border-radius: 90px;
  transition: 0.3s;
}

.o-switch:checked + .o-switch__label:before {
  background: #bada55;
}

.o-switch:checked + .o-switch__label:after {
  left: calc(100% - 5px);
  transform: translateX(-100%);
}

.o-switch__label:active:after {
  width: 130px;
}

.o-figure {
  display: block;
  margin-bottom: 0;
}

.c-widget--hph-teaser-links-external .o-figure {
  max-width: 70%;
  margin: auto;
}

@media (min-width: 62em) {
  .c-widget--hph-teaser-links-external .o-figure {
    max-width: 100%;
  }
}

.o-figure figcaption {
  font-size: 1rem;
  color: #999999;
}

.o-figure__placeholder {
  width: 100%;
  height: 100%;
  background-color: #efefef;
}

.o-figure__embedded {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.o-figure__embedded::before {
  display: block;
  content: "";
}

.o-figure__embedded iframe,
.o-figure__embedded embed,
.o-figure__embedded object,
.o-figure__embedded video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  margin-bottom: 1rem;
}

.o-figure__embedded--21by9::before {
  padding-top: 42.8571428571%;
}

.o-figure__embedded--16by9::before {
  padding-top: 56.25%;
}

.o-figure__embedded--4by3::before {
  padding-top: 75%;
}

.o-figure__embedded--1by1::before {
  padding-top: 100%;
}

@media (min-width: 62em) {
  .o-aside {
    margin-top: 5.3rem;
  }
}

.app-sidebar .o-aside {
  padding-left: calc(1.25rem * 1.5);
  padding-right: calc(1.25rem * 1.5);
}

@media (min-width: 62em) {
  .app-sidebar .o-aside {
    padding: 0;
  }
}

.o-separator {
  position: relative;
  width: 100%;
  padding-bottom: calc(calc(calc(1.25rem * 1.5) * 1.5) / 3.5);
}

.o-separator:not(:last-child)::after, .o-separator--default::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 90px;
  height: 0.25rem;
  background-color: #efefef;
}

@media (min-width: 62em) {
  .o-separator--sm {
    display: none;
  }
}

.o-read-more__icon {
  margin-right: .5rem;
}

.app-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 8rem;
  z-index: 100;
  display: block;
  background-color: whitesmoke;
  padding-bottom: 1rem;
}

@media (min-width: 768px) {
  .app-header {
    margin: 0 auto 1rem;
    padding-bottom: 1rem;
  }
  .plone-toolbar-left .app-header {
    left: 60px;
    width: calc(100% - 60px);
  }
  .plone-toolbar-left-expanded .app-header {
    left: 120px;
    width: calc(100% - 120px);
  }
}

.app-header.u-backdrop {
  background: #efefef;
}

.c-header {
  padding-top: 1.5rem;
}

@media (min-width: 992px) {
  .c-header {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .c-header {
    max-width: 1140px;
  }
}

.app-header__content {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 100%;
}

@media (min-width: 62em) {
  .app-header__content {
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-end;
    margin-right: auto;
    margin-left: auto;
  }
}

@media (min-width: 62em) and (min-width: 992px) {
  .app-header__content {
    max-width: 960px;
  }
}

@media (min-width: 62em) and (min-width: 1200px) {
  .app-header__content {
    max-width: 1140px;
  }
}

.app-header__section {
  display: block;
  position: relative;
  width: 50%;
  max-width: 50%;
  flex: 0 0 50%;
}

.app-header__section:first-child {
  width: 50%;
  max-width: 50%;
  flex: 0 0 50%;
}

.app-header__section:nth-child(2) {
  order: -1;
  width: 100%;
  max-width: 100%;
  flex: 0 0 100%;
  background: #efefef;
  padding-bottom: .5rem;
}

@media (min-width: 62em) {
  .app-header__section {
    width: 16.6666%;
    flex: 0 0 16.6666%;
  }
  .app-header__section:first-child {
    width: 50%;
    max-width: 50%;
    flex: 0 0 50%;
  }
  .app-header__section:nth-child(2) {
    order: 0;
    width: 33%;
    max-width: 33%;
    flex: 0 0 33.3333%;
    background: transparent;
    padding: 0;
  }
}

@media (min-width: 62em) and (min-width: 75em) {
  .app-header__section {
    width: 16.6666%;
    flex: 0 0 16.6666%;
  }
  .app-header__section:first-child {
    width: 50%;
    max-width: 50%;
    flex: 0 0 50%;
  }
  .app-header__section:nth-child(2) {
    order: 0;
    width: 25%;
    max-width: 25%;
    flex: 0 0 25%;
    background: transparent;
    padding: 0;
  }
}

@media (min-width: 62em) and (min-width: 75em) and (min-width: 75em) {
  .app-header__section--navigation {
    width: 12%;
    max-width: 12%;
    flex: 0 0 12%;
  }
}

@media (min-width: 62em) and (min-width: 75em) and (min-width: 75em) {
  .app-header__section--quick-links {
    margin-left: 9.5rem;
  }
}

.app-header__section--quick-links {
  display: flex;
}

.app-main {
  width: 100%;
  padding-top: 8rem;
  margin-left: auto;
  margin-right: auto;
}

.template-frontpage-view .app-main {
  padding-top: 0;
}

.app-main.u-no-scroll {
  position: fixed;
  left: 0;
  right: 0;
  overflow-y: scroll;
  /* render disabled scroll bar to keep the same width */
}

@media (min-width: 48em) and (max-width: 61.99em) {
  .app-main {
    padding-top: calc(8rem + 2rem);
  }
}

.app-main--fullscreen {
  padding: 0;
}

@media (min-width: 992px) {
  .app-main--grid {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .app-main--grid {
    max-width: 1140px;
  }
}

@media (min-width: 62em) {
  .app-main--grid {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
}

@media (min-width: 62em) {
  .app-main--grid .app-sidebar {
    flex: 1 0 25%;
    max-width: 25%;
  }
}

@media (min-width: 62em) {
  .app-main--grid .app-content {
    flex: 1 0 75%;
    max-width: 75%;
    padding-right: calc(1.25rem * 1.5);
  }
}

.app-sidebar {
  padding-right: calc(1.25rem * 1.5);
  padding-left: calc(1.25rem * 1.5);
}

@media (min-width: 62em) {
  .app-sidebar {
    padding: 0;
  }
}

.app-footer {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.app-footer__content {
  padding: calc(1.25rem * 1.5);
}

.app-footer__content--primary {
  color: #fff;
  background-color: #182f7a;
}

.app-footer__main {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 992px) {
  .app-footer__main {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .app-footer__main {
    max-width: 1140px;
  }
}

@media (min-width: 62em) {
  .app-footer__main {
    padding: 0;
  }
}

.app-footer__aside {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 992px) {
  .app-footer__aside {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .app-footer__aside {
    max-width: 1140px;
  }
}

@media (min-width: 62em) {
  .app-footer__aside {
    padding: 0;
  }
}

.c-identifier {
  position: fixed;
  left: 0;
  bottom: 0;
  padding: .25rem 1rem;
  width: 100%;
  display: block;
  color: #182f7a;
  background-color: #eed343;
  font-size: 1rem;
}

@media (min-width: 768px) {
  .plone-toolbar-left .c-identifier {
    left: 60px;
    width: calc(100% - 60px);
  }
  .plone-toolbar-left-expanded .c-identifier {
    left: 120px;
    width: calc(100% - 120px);
  }
}

.c-identifier__content {
  font-size: 1rem;
  font-family: "Taz-SemiBold";
  text-align: right;
}

.c-sign-in {
  margin: 1rem;
  padding: 1rem;
  border: 1px solid #efefef;
  background: #fff;
}

@media (min-width: 36em) {
  .c-sign-in {
    width: 576px;
  }
}

.c-sign-in__header {
  padding: 2rem;
  text-align: center;
}

.c-button {
  display: inline-block;
  font-weight: 400;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
  white-space: normal;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  font-family: "Taz-SemiBold";
  padding: 0.375rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .c-button {
    transition: none;
  }
}

.c-button:hover, .c-button:focus {
  text-decoration: none !important;
}

.c-button:focus, .c-button.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.c-button.disabled, .c-button--disabled, .c-button:disabled {
  opacity: .5;
}

.c-button:not(:disabled):not(.disabled):active, .c-button:not(:disabled):not(.disabled).active {
  background-image: none;
}

@media (min-width: 768px) {
  .c-button {
    white-space: nowrap;
  }
}

a.c-button.disabled,
a.c-button--disabled,
fieldset[disabled] a.c-button {
  pointer-events: none;
}

a.c-button--active-action {
  pointer-events: none;
  border-width: 0 0 1px 0;
}

.c-button--default {
  color: #5a5f64;
  border-color: #5a5f64;
}

.c-button--default:hover {
  color: #fff;
  background-color: #5a5f64;
  border-color: #5a5f64;
}

.c-button--default:focus, .c-button--default.focus {
  box-shadow: 0 0 0 0.2rem rgba(90, 95, 100, 0.5);
}

.c-button--default.disabled, .c-button--default:disabled {
  color: #5a5f64;
  background-color: transparent;
}

.c-button--default:not(:disabled):not(.disabled):active, .c-button--default:not(:disabled):not(.disabled).active,
.show > .c-button--default.dropdown-toggle {
  color: #fff;
  background-color: #5a5f64;
  border-color: #5a5f64;
}

.c-button--default:not(:disabled):not(.disabled):active:focus, .c-button--default:not(:disabled):not(.disabled).active:focus,
.show > .c-button--default.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(90, 95, 100, 0.5);
}

.searchPage .c-button--default {
  color: #fff;
  border-color: #fff;
}

.searchPage .c-button--default:hover {
  color: #182f7a;
  background-color: #fff;
  border-color: #fff;
}

.searchPage .c-button--default:focus, .searchPage .c-button--default.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.searchPage .c-button--default.disabled, .searchPage .c-button--default:disabled {
  color: #fff;
  background-color: transparent;
}

.searchPage .c-button--default:not(:disabled):not(.disabled):active, .searchPage .c-button--default:not(:disabled):not(.disabled).active,
.show > .searchPage .c-button--default.dropdown-toggle {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
}

.searchPage .c-button--default:not(:disabled):not(.disabled):active:focus, .searchPage .c-button--default:not(:disabled):not(.disabled).active:focus,
.show > .searchPage .c-button--default.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.searchPage .c-button--default:hover {
  color: #fff;
  background: #1e3b9a;
}

.c-button--danger {
  color: #900707;
  border-color: #900707;
}

.c-button--danger:hover {
  color: #fff;
  background-color: #900707;
  border-color: #900707;
}

.c-button--danger:focus, .c-button--danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(144, 7, 7, 0.5);
}

.c-button--danger.disabled, .c-button--danger:disabled {
  color: #900707;
  background-color: transparent;
}

.c-button--danger:not(:disabled):not(.disabled):active, .c-button--danger:not(:disabled):not(.disabled).active,
.show > .c-button--danger.dropdown-toggle {
  color: #fff;
  background-color: #900707;
  border-color: #900707;
}

.c-button--danger:not(:disabled):not(.disabled):active:focus, .c-button--danger:not(:disabled):not(.disabled).active:focus,
.show > .c-button--danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(144, 7, 7, 0.5);
}

.c-button--primary {
  color: #fff;
  background-color: #5a5f64;
  border-color: #5a5f64;
}

.c-button--primary:hover {
  color: #fff;
  background-color: #484c50;
  border-color: #424649;
}

.c-button--primary:focus, .c-button--primary.focus {
  color: #fff;
  background-color: #484c50;
  border-color: #424649;
  box-shadow: 0 0 0 0.2rem rgba(115, 119, 123, 0.5);
}

.c-button--primary.disabled, .c-button--primary:disabled {
  color: #fff;
  background-color: #5a5f64;
  border-color: #5a5f64;
}

.c-button--primary:not(:disabled):not(.disabled):active, .c-button--primary:not(:disabled):not(.disabled).active,
.show > .c-button--primary.dropdown-toggle {
  color: #fff;
  background-color: #424649;
  border-color: #3c3f42;
}

.c-button--primary:not(:disabled):not(.disabled):active:focus, .c-button--primary:not(:disabled):not(.disabled).active:focus,
.show > .c-button--primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(115, 119, 123, 0.5);
}

.c-button--link {
  color: #fff;
  background-color: #5a5f64;
  border-color: #5a5f64;
}

.c-button--link:hover {
  color: #fff;
  background-color: #484c50;
  border-color: #424649;
}

.c-button--link:focus, .c-button--link.focus {
  color: #fff;
  background-color: #484c50;
  border-color: #424649;
  box-shadow: 0 0 0 0.2rem rgba(115, 119, 123, 0.5);
}

.c-button--link.disabled, .c-button--link:disabled {
  color: #fff;
  background-color: #5a5f64;
  border-color: #5a5f64;
}

.c-button--link:not(:disabled):not(.disabled):active, .c-button--link:not(:disabled):not(.disabled).active,
.show > .c-button--link.dropdown-toggle {
  color: #fff;
  background-color: #424649;
  border-color: #3c3f42;
}

.c-button--link:not(:disabled):not(.disabled):active:focus, .c-button--link:not(:disabled):not(.disabled).active:focus,
.show > .c-button--link.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(115, 119, 123, 0.5);
}

.c-button + .c-button {
  margin-left: calc(1.25rem * .5);
}

.c-button--sm {
  padding: 0.25rem 0.5rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0;
}

.c-button--reorder {
  cursor: move;
  pointer-events: none;
}

.c-call-out {
  background-color: #efefef;
  padding: calc(1.25rem * 1.5 / 2);
  border-left: 1rem solid #ccc;
}

.c-alert {
  padding: calc(1.25rem * 1.5 / 2) calc(1.25rem * 1.5);
  margin: calc(1.25rem * 1.5 / 2) auto;
  border: 1px solid #eee;
  border-left-width: .25rem;
}

@media (min-width: 992px) {
  .app-main > .c-alert {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .app-main > .c-alert {
    max-width: 1140px;
  }
}

.c-alert__title {
  margin-top: 0;
  margin-bottom: .25rem;
}

.c-alert p:last-child {
  margin-bottom: 0;
}

.c-alert__button {
  float: right;
  margin-bottom: .5rem;
}

.c-alert--default {
  color: #000;
  background-color: #fff;
  border-color: #adb5bd;
}

.c-alert--default hr {
  border-top-color: #9fa8b2;
}

.c-alert--default .alert-link {
  color: black;
}

.c-alert--success {
  color: #28a745;
  background-color: #fff;
  border-color: #28a745;
}

.c-alert--success hr {
  border-top-color: #23923d;
}

.c-alert--success .alert-link {
  color: #1e7e34;
}

.c-alert--danger {
  color: #dc3545;
  background-color: #fff;
  border-color: #dc3545;
}

.c-alert--danger hr {
  border-top-color: #d32535;
}

.c-alert--danger .alert-link {
  color: #bd2130;
}

.c-alert--warning {
  color: #ffc107;
  background-color: #fff;
  border-color: #ffc107;
}

.c-alert--warning hr {
  border-top-color: #edb100;
}

.c-alert--warning .alert-link {
  color: #d39e00;
}

.c-alert--primary {
  color: #212529;
  background-color: #fff;
  border-color: #5a5f64;
}

.c-alert--primary hr {
  border-top-color: #4e5257;
}

.c-alert--primary .alert-link {
  color: #0a0c0d;
}

.c-control-panel__header {
  display: inline-block;
  padding: 0.5rem 1rem;
  margin-top: 3rem;
  color: #fff;
  background: #182f7a;
  font-family: "Taz-SemiBold";
  font-size: 2rem;
  text-transform: uppercase;
  line-height: 1;
}

@media (max-width: 1200px) {
  .c-control-panel__header {
    font-size: calc(1.325rem + 0.9vw) ;
  }
}

.c-configlet,
.configlets {
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-left: 0;
  padding-left: 0;
  list-style: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'/%3E");
}

.c-configlet > form,
.configlets > form {
  padding-top: 1rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.c-configlet > form:not(:first-child),
.configlets > form:not(:first-child) {
  border-top: 1px solid #5a5f64;
}

.c-configlet > form > button,
.configlets > form > button {
  float: right;
  width: auto;
}

.c-pagination {
  padding: 0.5rem 0;
  margin-bottom: 1.5rem;
}

.c-pagination__list {
  display: flex;
  margin-left: 0;
  padding-left: 0;
  list-style: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'/%3E");
  flex-wrap: wrap;
}

.c-pagination__link {
  position: relative;
  display: block;
  cursor: pointer;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #182f7a;
  background-color: #fff;
  border: 1px solid #efefef;
}

.c-pagination__link:hover {
  z-index: 2;
  color: #182f7a;
  text-decoration: none;
  background-color: #efefef;
  border-color: #efefef;
}

.c-pagination__link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: none;
}

.c-pagination__list-item--previous .c-pagination__link > .label {
  display: none;
}

.c-pagination__list-item--previous .c-pagination__link > .arrow:before {
  content: "\00ab";
}

.c-pagination__list-item--next .c-pagination__link > .label {
  display: none;
}

.c-pagination__list-item--next .c-pagination__link > .arrow:before {
  content: "\00bb";
}

.c-pagination__list-item {
  display: block;
}

.c-pagination__list-item:first-child .page-link {
  margin-left: 0;
}

.c-pagination__list-item--active {
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #182f7a;
  background-color: #fff;
  border: 1px solid #efefef;
  color: #fff;
  background-color: #182f7a;
  border-color: #182f7a;
}

.c-pagination__list-item--active > span {
  z-index: 1;
}

.app-nav-tree {
  display: none;
  background-color: transparent;
  font-weight: 500;
}

@media (min-width: 992px) {
  .app-nav-tree {
    display: block;
  }
}

.app-nav__list {
  padding-left: 0;
  list-style: none;
}

.app-nav__list--level-1, .app-nav__list--level-2 {
  margin-left: .5rem;
}

.app-nav-tree-list {
  padding-left: 0;
  list-style: none;
}

.app-nav-tree-list li {
  width: 100%;
}

.app-nav__list-item {
  text-transform: uppercase;
  margin-left: 0;
  padding: .5rem .25rem;
}

.app-nav__list-item:not(last) {
  margin-bottom: .25rem;
  border-bottom: 1px solid #5a5f64;
}

.app-nav__list-item:last-of-type {
  border-bottom: 0px;
}

.app-nav__list-link {
  color: #000;
}

.app-nav__list-link:hover {
  color: #101f4f;
}

.app-nav__list-link.navTreeCurrentNode {
  color: #5a5f64;
}

.app-nav__list-link--open {
  color: #182f7a;
}

.app-nav__list-link--active {
  color: #182f7a;
}

.app-nav__list--level-0 {
  margin-bottom: 0;
}

.app-nav__list--level-1 {
  margin-left: 0;
}

.c-nav-bar {
  position: fixed;
  top: 0;
  z-index: 1001;
  width: 90vw;
  height: 100vh;
  overflow-y: scroll;
  right: -100%;
  border-top: 0px solid #212529;
  background-color: #182f7a;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.25), 0 0.65rem 0.65rem rgba(0, 0, 0, 0.22);
}

@supports (--custom: property) {
  .c-nav-bar {
    height: calc(var(--vh, 1vh) * 100);
  }
}

.c-nav-bar--overlay {
  pointer-events: all;
  right: 0;
  transition: right .5s linear;
}

@media (min-width: 48em) {
  .c-nav-bar--overlay {
    width: 50vw;
  }
}

@media (min-width: 277777.75em) {
  .c-nav-bar {
    position: relative;
    display: block;
    right: 0;
    min-height: 3rem;
    background: #6c757d;
  }
}

.c-nav-bar--hidden {
  transition: right .5s linear;
}

.c-nav-bar__header {
  display: flex;
  padding-left: 1rem;
  padding-right: 0;
  margin-bottom: 1rem;
  background-color: #182f7a;
  color: #fff;
  border-bottom: 2px solid rgba(255, 255, 255, 0.1);
}

@media (min-width: 48em) {
  .c-nav-bar__header {
    padding-left: 2rem;
    padding-right: 1rem;
  }
}

.c-nav-bar__title {
  width: 100%;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  font-family: "Taz-SemiBold";
  text-transform: uppercase;
  font-size: 1.25rem;
}

.c-nav-bar__toggle {
  width: 4rem;
  line-height: 1;
  background: transparent;
  border: 1px solid transparent;
  color: #fff;
}

.app-nav__toggle-icon > polygon {
  fill: #fff;
}

.c-nav {
  position: relative;
  width: 100%;
  height: calc(100% - 120px);
  overflow: visible;
  padding: 0 1rem;
  list-style: none;
  background-color: #182f7a;
}

@media (min-width: 48em) {
  .c-nav {
    padding-right: 2rem;
    padding-left: 2rem;
  }
}

@media (min-width: 277777.75em) {
  .c-nav {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 0;
    margin-bottom: 0;
    padding-left: .5rem;
    justify-content: space-between;
  }
}

.c-nav--level-1 {
  position: relative;
  overflow-y: visible;
}

.c-nav__item {
  position: relative;
  overflow: visible;
  width: 100%;
  margin: 0;
  padding: 0;
  border-bottom: 2px solid rgba(255, 255, 255, 0.1);
  background-color: #182f7a;
}

@media (min-width: 277777.75em) {
  .c-nav__item {
    width: auto;
    flex: 1 0 0;
    padding: 1rem 0;
    margin-right: 1rem;
    border: none;
  }
}

@media (min-width: 1200px) {
  .c-nav__item {
    margin-right: 1rem;
  }
}

.c-nav__item--back-link, .c-nav__item--parent {
  border-bottom: 2px solid rgba(255, 255, 255, 0.1);
  display: flex;
  padding: 0;
}

.c-nav__dropdown--open .c-nav__item {
  padding-left: 1rem;
}

.c-nav__dropdown--open .c-nav__item:last-child {
  border-bottom: 0px solid #6c757d;
}

.c-nav__item--has-children {
  position: relative;
}

.c-nav__link {
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: .75rem;
  padding-bottom: .75rem;
  text-decoration: none;
  color: #fff;
  font-family: "Taz-SemiBold";
  text-transform: none;
  font-weight: 400;
  line-height: .7rem;
  transition: background-color 0.5s ease;
  font-size: 1.3rem;
}

.c-nav-bar__toggle .c-nav__link {
  line-height: 1rem;
}

@media (min-width: 277777.75em) {
  .c-nav__link {
    text-transform: uppercase;
  }
}

.c-nav__item--current > .c-nav__link {
  color: #eed343;
}

.c-nav__link:hover {
  text-decoration: none;
  background: transparent;
  color: #eed343;
}

.c-nav__link--active, .c-nav__link--open {
  text-decoration: none;
  background: transparent;
}

.app-nav--level-1 .c-nav__link:after {
  display: none;
}

@media (min-width: 277777.75em) {
  .c-nav__link:after {
    display: none;
  }
}

.c-nav__dropdown .c-nav__link {
  padding-top: .5rem;
  padding-bottom: .5rem;
  font-size: 1.2rem;
}

.c-nav__link-text {
  flex: 1 0 calc(100% - 2rem);
  max-width: calc(100% - 2rem);
  line-height: 1.5;
}

.c-nav__link--action {
  flex: 0 0 3rem;
  max-width: 3rem;
  padding-left: .75rem;
  align-self: flex-end;
}

.c-nav__item--parent .c-nav__link--action {
  width: 2rem;
  padding-left: .25rem;
}

.c-nav__link--back-link {
  height: 3rem;
}

@media (max-width: 277777.74em) {
  .c-nav__link--has-icon {
    display: flex;
  }
}

.app-nav__tab {
  display: inline-block;
  width: 100%;
  padding: 1rem;
}

.app-nav__link--has-icon .app-nav__tab {
  display: block;
}

@media (min-width: 277777.75em) {
  .app-nav__link--has-icon .app-nav__tab {
    display: inline-block;
  }
}

.app-nav__tab--hide-text {
  text-indent: -9999px;
  overflow: hidden;
  width: 0;
  padding: 0;
}

.app-nav__tab--action {
  display: inline-block;
  margin-top: .5rem;
  padding: 0;
  width: 2rem;
  height: 2rem;
}

@media (min-width: 277777.75em) {
  .app-nav__tab--action {
    display: none;
  }
}

.app-nav__link--back-link .app-nav__tab--action {
  display: inline-block;
}

@media (min-width: 277777.75em) {
  .app-nav__tab {
    padding: 0;
  }
}

.c-nav__toggle {
  display: flex;
  align-items: center;
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
  padding: 0;
  width: 1rem;
  height: 1rem;
  line-height: 1;
  border-radius: 50%;
}

.c-nav__toggle--close {
  border: 1px solid #dee2e6;
}

@media (min-width: 277777.75em) {
  .c-nav__toggle {
    display: none;
  }
}

.app-nav__link--back-link .c-nav__toggle {
  display: inline-block;
}

.c-nav-bar__toggle .c-nav__toggle {
  width: 2rem;
  height: 2rem;
}

.c-nav__item--has-dropdown .c-nav__toggle {
  transform: rotate(90deg);
}

.c-nav__dropdown {
  display: none;
  z-index: 1002;
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: #182f7a;
}

.c-nav__dropdown--open {
  display: block;
  height: auto;
  margin-bottom: 1rem;
}

.c-quick-links .c-nav__dropdown--open {
  margin-bottom: 0;
}

.c-nav-list {
  margin-left: 0;
  padding-left: 0;
  list-style: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'/%3E");
  font-family: "Taz-SemiBold";
}

.c-nav-list__item {
  border-bottom: 1px solid #fff;
  background-color: #efefef;
}

.c-nav-list__link {
  display: block;
  width: 100%;
  padding: 0.5rem calc(0.5rem * 2);
  transition: none;
}

.c-nav-list__link--active {
  color: #fff;
  background-color: #999999;
}

.c-nav-list__link--active:hover {
  color: #fff;
  transition: none;
  text-decoration: none;
}

.c-nav-list--level-0 .c-nav-list__link {
  padding-left: calc(0.5rem * 2);
}

.c-nav-list--level-1 .c-nav-list__link {
  padding-left: calc(0.5rem * 3);
}

.c-nav-list--level-2 .c-nav-list__link {
  padding-left: calc(0.5rem * 4);
}

.c-quick-links {
  display: block;
  position: relative;
  flex: 1 0 50%;
  max-width: 50%;
  padding-left: calc(1.25rem * 1.5);
}

@media (min-width: 62em) {
  .c-quick-links {
    padding-left: 0;
  }
}

.c-quick-links__button {
  display: flex;
  flex-direction: row;
  line-height: 1;
  background: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  padding: 0;
}

@media (min-width: 62em) {
  .c-quick-links__button {
    width: 100%;
  }
}

.c-quick-links__text {
  align-self: flex-end;
  font-family: "Taz-SemiBold";
  font-size: 0.8rem;
  color: #182f7a;
  text-transform: uppercase;
}

@media (min-width: 62em) {
  .c-quick-links__text {
    font-size: 1.45rem;
  }
}

.c-quick-links__icon {
  margin-right: .25rem;
  font-size: 1.5rem;
  color: #182f7a;
  width: 1rem;
  height: 1.3rem;
}

@media (min-width: 62em) {
  .c-quick-links__icon {
    width: 1.3rem;
    height: 1.6rem;
  }
}

.c-language-menu {
  display: block;
  position: relative;
  flex: 1 0 50%;
  max-width: 50%;
  padding-right: calc(1.25rem * 1.5);
}

@media (min-width: 62em) {
  .c-language-menu {
    padding-right: 0;
  }
}

.c-language-menu__button {
  display: flex;
  flex-direction: row-reverse;
  line-height: 1;
  background: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

@media (min-width: 62em) {
  .c-language-menu__button {
    width: 100%;
    justify-content: center;
  }
}

.c-language-menu__text {
  align-self: flex-end;
  font-family: "Taz-SemiBold";
  font-size: 0.8rem;
  color: #182f7a;
  text-transform: uppercase;
}

@media (min-width: 62em) {
  .c-language-menu__text {
    font-size: 1.45rem;
  }
}

.c-language-menu__icon {
  margin-right: .5rem;
  font-size: 1.5rem;
  color: #182f7a;
  width: 1rem;
  height: 1.3rem;
}

@media (min-width: 62em) {
  .c-language-menu__icon {
    width: 1.3rem;
    height: 1.6rem;
  }
}

.app-pathbar {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: calc(1.25rem * 1.5);
  padding-left: calc(1.25rem * 1.5);
}

@media (min-width: 992px) {
  .app-pathbar {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .app-pathbar {
    max-width: 1140px;
  }
}

.template-frontpage-view .app-pathbar {
  display: none;
}

.template-language-root-view .app-pathbar {
  display: none;
}

.app-main--grid .app-pathbar {
  flex: 0 0 100%;
  max-width: 100%;
}

@media (min-width: 62em) {
  .app-pathbar {
    padding-right: 0;
    padding-left: 0;
  }
}

.c-breadcrumbs {
  margin-left: 0;
  padding-left: 0;
  list-style: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'/%3E");
  display: flex;
  flex-wrap: wrap;
  padding: calc(1.25rem * 1.5 / 2) 0;
  margin-bottom: calc(1.25rem * 1.5);
  background-color: transparent;
}

.c-breadcrumbs__item {
  font-size: 1rem;
}

.c-breadcrumbs__item + .c-breadcrumbs__item {
  padding-left: 0.5rem;
}

.c-breadcrumbs__item + .c-breadcrumbs__item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #5a5f64;
  content: "/";
}

.c-banner--front-page {
  margin-top: 8.5rem;
}

@media (min-width: 992px) {
  .c-banner--front-page {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .c-banner--front-page {
    max-width: 1140px;
  }
}

.c-banner__content {
  text-align: center;
  background-color: #fff;
  background: url("../assets/images/static-banner.jpg") center no-repeat;
  background-size: cover;
  color: #fff;
  min-height: 400px;
}

@media (min-width: 48em) {
  .c-banner__content {
    min-height: 600px;
  }
}

.c-banner__header {
  position: absolute;
  bottom: 14%;
  left: 0;
  padding: 2rem;
  font-family: "Taz-SemiBold";
  font-size: 1.3rem;
  font-weight: 400;
  margin-bottom: .5rem;
  line-height: 1rem;
}

@media (min-width: 48em) {
  .c-banner__header {
    font-size: 1.5rem;
  }
}

@media (min-width: 62em) {
  .c-banner__header {
    bottom: 14%;
    padding: 0 6rem;
    font-size: 2rem;
  }
}

.c-banner__description {
  text-align: left;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 4rem 2rem;
  font-size: 1rem;
}

@media (min-width: 62em) {
  .c-banner__description {
    padding: 0 6rem 4rem 6rem;
  }
}

.c-snippet {
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: 1.25rem;
}

@media (min-width: 48em) {
  .c-snippet {
    flex-direction: row-reverse;
    flex-wrap: wrap;
  }
}

.c-snippet__aside {
  flex: 1 0 100%;
  padding-bottom: 1.25rem;
}

@media (min-width: 48em) {
  .c-snippet__aside {
    flex: 1 0 25%;
    max-width: 25%;
    padding-right: 1.25rem;
    padding-bottom: 0;
  }
}

.c-snippet__main {
  display: flex;
  flex-direction: column;
  flex: 1 0 100%;
  padding-bottom: 1.25rem;
}

@media (min-width: 48em) {
  .c-snippet__main {
    flex: 1 0 75%;
    max-width: 75%;
    padding-bottom: calc(1.25rem / 2);
  }
}

.c-snippet__footer {
  font-size: 1rem;
  font-family: "Taz-SemiBold";
  padding-top: calc(1.25rem / 2);
}

.c-snippet__title {
  font-weight: 500;
}

.c-snippet__section {
  font-size: 1.25rem;
}

.c-page-department {
  font-family: "Taz-SemiBold";
  margin-bottom: 1rem;
}

.c-snippet__abstract {
  color: #212529;
  font-size: 1.25rem;
}

.c-snippet__link {
  display: block;
}

.c-snippet__link--abstract {
  color: #212529;
}

.c-snippet__link--abstract:hover {
  color: #212529;
  text-decoration: none;
}

@media (min-width: 36em) and (max-width: 47.99em) {
  .c-snippet__figure--constrained-width-md, .c-snippet__figure--faculty {
    max-width: 50%;
  }
}

@keyframes toolBarEntrance {
  from {
    height: 0;
  }
  to {
    height: 3.5rem;
  }
}

.u-animation--grow {
  animation-name: toolBarEntrance;
}

.c-toolbar {
  display: block;
}

.c-toolbar--default {
  margin-bottom: 1.5rem;
}

.c-toolbar--primary {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  animation-name: toolBarEntrance;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.c-toolbar + .o-page__header {
  margin-top: 3.5rem;
}

.c-toolbar__content {
  display: flex;
  padding: 0.5rem calc(1.25rem * 1.5 / 2);
  color: #d9d9d9;
  background-color: #212529;
}

.c-toolbar__item {
  display: flex;
  align-items: center;
  flex: 1 0 auto;
}

.c-toolbar__title {
  text-align: center;
  font-family: "Taz-SemiBold";
}

.c-toolbar__actions {
  width: 100%;
  text-align: right;
}

.c-toolbar__action {
  color: #d9d9d9;
}

.c-panels {
  display: block;
  width: 100%;
  overflow: visible;
}

.c-panel {
  position: relative;
  padding: 0 0 0 0;
}

.c-panels--header .c-panel--1 {
  padding-top: 0;
}

@media (min-width: 48em) {
  .c-panels--header .c-panel--1 {
    padding-top: 1rem;
  }
}

.c-panel--full-width {
  width: 100vw;
  margin-left: 50%;
  transform: translateX(-50%);
}

.c-panel--centered {
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 992px) {
  .c-panel--centered {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .c-panel--centered {
    max-width: 1140px;
  }
}

.c-panel--primary {
  color: #212529;
  background-color: whitesmoke;
}

.c-panel--secondary {
  background-color: whitesmoke;
}

.c-panel--disabled {
  opacity: .5;
}

.c-panel__header {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  opacity: 1;
  transition: opacity .25s ease-in-out;
}

.c-panels--view .c-panel__header {
  display: none;
}

.c-panel__main {
  position: relative;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-top: calc(1.25rem * 1.5);
  padding-bottom: calc(1.25rem * 1.5);
}

@media (min-width: 768px) {
  .c-panel__main {
    padding-bottom: calc(1.25rem * 1.5);
  }
}

@media (min-width: 992px) {
  .c-panel__main {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .c-panel__main {
    max-width: 1140px;
  }
}

@media (min-width: 992px) {
  .c-panel__main--centered {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .c-panel__main--centered {
    max-width: 1140px;
  }
}

.c-panel--1 .c-panel__main {
  padding-top: 0;
}

.c-panel--full-width .c-panel__main {
  padding-right: calc(1.25rem * 1.5);
  padding-left: calc(1.25rem * 1.5);
}

@media (min-width: 62em) {
  .c-panel--full-width .c-panel__main {
    padding-right: 0;
    padding-left: 0;
  }
}

.c-panels--view .c-panel__footer {
  display: none;
}

.c-panel__icon {
  display: none;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  z-index: 11;
  border: 0.125rem solid #212529;
  border-radius: 50%;
  color: #fff !important;
  background-color: #212529;
  font-size: 1rem;
  font-family: "Taz-SemiBold";
}

.c-panel__icon:hover {
  color: #efefef;
}

.c-panel__header:hover .c-panel__icon {
  display: flex;
}

.c-panel__icon--fixed-bottom {
  position: absolute;
  bottom: 0;
}

.c-panel__controls {
  position: relative;
  left: calc(100% - 2rem);
  display: flex;
  width: 2rem;
  height: 0;
  color: #fff;
  background-color: #212529;
  transition: height .25s ease;
}

.c-panel__controls::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: calc(50% - 0.2rem / 2);
  overflow: hidden;
  width: 0.2rem;
  height: 0;
  background-color: #212529;
  transition: height .25s ease;
}

.c-panel__header:hover .c-panel__controls {
  height: 100%;
}

.c-panel__header:hover .c-panel__controls::before {
  height: 100%;
}

.c-panel-factory {
  position: relative;
  left: 0;
  opacity: 0;
  transition: opacity .25s ease-in-out;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 0.25rem 0;
}

.c-panel-factory::before {
  content: "";
  display: block;
  position: absolute;
  top: calc(50% - 0.2rem / 2);
  left: 0;
  overflow: hidden;
  width: 0;
  height: 0.2rem;
  background-color: #212529;
  transition: width .25s ease;
}

.c-panel-factory:hover {
  opacity: 1;
}

.c-panel-factory:hover::before {
  width: 100%;
}

.c-panels--view .c-panel-factory {
  display: none;
}

.c-panel-factory__action {
  position: relative;
  left: calc(0 - 2rem / 2);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  border: 0.125rem solid #212529;
  border-radius: 50%;
  color: #fff;
  background-color: #212529;
  font-size: 1rem;
  font-family: "Taz-SemiBold";
}

.c-panel-factory__action:hover {
  color: #efefef;
}

.c-panel-editor {
  position: relative;
}

.c-panel-editor--centered {
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 992px) {
  .c-panel-editor--centered {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .c-panel-editor--centered {
    max-width: 1140px;
  }
}

.c-panel-editor__header {
  height: 4rem;
  min-height: 4rem;
}

.c-panel-editor__section-headline {
  padding: .5rem;
  margin-bottom: 1rem;
  border-bottom: 0.0125rem solid #212529;
}

.c-panel-editor__section-headline {
  font-family: "Taz-SemiBold";
  text-transform: uppercase;
}

.c-panel-editor__footer {
  padding: calc(1.25rem * 1.5) 0;
}

.c-panel-overlay {
  background-color: #fff;
  overflow: scroll;
}

.c-panel-overlay--active {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 1001;
  width: 100%;
}

.c-panel-overlay__content {
  padding: calc(1.25rem * 1.5) calc(calc(1.25rem * 1.5) * 1.5) 0;
  text-align: right;
}

.c-card {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding-bottom: calc(1.25rem * 1.5);
}

.c-list__item .c-card {
  padding-bottom: 0;
}

.c-card--spacer {
  padding: calc(1.25rem * 1.5 / 2);
}

.c-card--nav {
  justify-content: flex-start;
}

.c-card__overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.c-card__header {
  background: transparent;
  font-family: "Taz-SemiBold";
}

.c-card__header--faculty {
  display: none;
}

@media (min-width: 62em) {
  .c-card--teaser .c-card__header {
    flex: 1 0 6.5rem;
  }
}

.c-card__main {
  font-family: "adobe-garamond-pro";
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
}

.c-card--news .c-card__main {
  font-size: 1.25rem;
}

.c-card--teaser .c-card__main {
  padding-bottom: calc(1.25rem * 1.5);
}

@media (min-width: 62em) {
  .c-card--nav .c-card__main {
    max-height: 1500px;
    display: block;
  }
}

.c-card__footer {
  margin-top: auto;
  font-family: "Taz-SemiBold";
  font-size: 1rem;
}

.c-card--news .c-card__footer {
  text-align: left;
}

.c-card--teaser-links .c-card__footer {
  text-align: center;
}

.c-card__footer--centered {
  text-align: center;
}

.c-card__footer--action {
  display: inline-block;
  margin-top: .7rem;
  padding: 0;
  width: 1.1rem;
  height: 1.1rem;
  border: 1px solid #212529;
  line-height: 1;
  border-radius: 50%;
}

.c-card__footer--link {
  display: inline-block;
  padding: 0 0 0 .5rem;
  margin-top: .5rem;
  color: #182f7a;
}

.c-card__link {
  display: block;
  color: #182f7a;
  text-decoration: none;
}

.c-card__link:hover {
  color: #0a0c0d;
  text-decoration: none;
}

.c-card__date {
  font-weight: 100;
  font-size: 1.25rem;
  color: #182f7a;
}

.c-card__title {
  font-family: "Taz-SemiBold";
  color: #182f7a;
}

.c-card__section--centered {
  text-align: center;
}

.c-card__detail {
  font-family: "Taz-SemiBold";
}

.c-card--banner .c-card__header,
.c-card--banner .c-card__footer {
  text-align: center;
}

.c-card--publications {
  padding-right: 0;
  padding-left: 0;
}

@media (min-width: 48em) {
  .c-card--publications {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}

.site-en .c-card__title--de {
  display: none;
}

:not(.site-en) .c-card__title--en {
  display: none;
}

.site-en .c-card__title--en {
  display: block;
}

.c-toc {
  font-family: "Taz-SemiBold";
  position: relative;
  font-size: 1.25rem;
  margin-left: 0;
  padding-left: 0;
  list-style: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'/%3E");
}

@media (min-width: 75em) {
  .c-toc {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.c-toc__item {
  display: none;
  padding: .35rem 0 .35rem 0;
  text-transform: none;
  line-height: 1.5;
}

.c-toc__item--has-subtree {
  display: flex;
  border-right: 1px solid #fff;
  flex-wrap: wrap;
}

@media (min-width: 75em) {
  .c-toc__item--has-subtree {
    flex-direction: column;
  }
}

.c-toc__item.en {
  display: none;
}

.c-toc--level-1 .c-toc__item {
  display: flex;
  text-transform: none;
  margin-bottom: 0;
  border-right: 0;
  padding-left: 0;
  padding-right: 0;
}

@media (min-width: 75em) {
  .c-toc--level-1 .c-toc__item {
    flex-direction: column;
    flex: 1 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 75em) {
  .c-toc__item {
    padding: .3rem 1.5rem;
    flex: 1 0 16.6666%;
    max-width: 16.6666%;
    margin-bottom: 0;
  }
  .c-toc__item:first-of-type {
    padding-left: 0;
  }
}

.c-toc__item--has-subtree {
  border: 0px solid rgba(255, 255, 255, 0.1);
}

@media (min-width: 75em) {
  .c-toc__item--has-subtree:nth-child(even) {
    border-left-width: 2px;
  }
}

@media (min-width: 75em) {
  .c-toc__item--has-subtree:not(:first-child) {
    border-left-width: 2px;
  }
}

.c-toc--level-1 {
  display: none;
  text-transform: none;
}

@media (max-width: 74.99em) {
  .c-toc--level-1 {
    flex: 1 0 100%;
    max-width: 100%;
  }
}

.show .c-toc--level-1 {
  display: block;
}

@media (min-width: 75em) {
  .c-toc--level-1 {
    display: block;
  }
}

.c-toc__link {
  display: flex;
  width: 100%;
  min-height: calc(1.25rem * 1.5);
  line-height: 1.25;
  color: #fff;
  font-size: 1.25rem;
}

@media (min-width: 75em) {
  .c-toc__link {
    display: block;
    height: calc(1.25rem * 2.5);
    text-decoration: none;
    cursor: pointer;
  }
}

.c-toc--level-1 .c-toc__link {
  display: block;
  height: auto;
  font-size: 1rem;
}

.c-toc__link:hover {
  color: #fff;
}

.c-toc__link--toggle {
  text-decoration: none;
  cursor: default;
}

.c-toc__link--toggle:hover {
  text-decoration: none;
}

.c-toc__link-item {
  flex: 1 0 50%;
  max-width: 50%;
  line-height: 1.2rem;
}

.c-toc__link-item--icon {
  text-align: right;
  color: #fff;
}

@media (min-width: 75em) {
  .c-toc__link-item--icon {
    display: none;
  }
}

.c-toc__toggle {
  display: block;
  flex: 1 0 50%;
  color: #fff;
  text-align: right;
}

@media (min-width: 75em) {
  .c-toc__toggle {
    display: none;
  }
}

.c-toc__icon {
  color: currentColor;
}

.c-toc__icon--close {
  display: none;
}

.show .c-toc__icon--open {
  display: none;
}

.show .c-toc__icon--close {
  display: inline-block;
}

.c-social-menu {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  max-width: 100%;
  margin-bottom: calc(1.25rem * 1.5 / 2);
}

@media (min-width: 36em) {
  .c-social-menu {
    width: 360px;
  }
}

.c-social-menu--primary {
  margin: 0 auto 1rem auto;
}

.c-social-menu__item {
  flex: 1 0 25%;
  max-width: 25%;
  text-align: center;
}

.c-social-menu__link {
  display: inline-block;
  border: 1px solid currentColor;
  border-radius: 50%;
}

.c-social-bar {
  display: block;
  width: 100%;
  color: #fff;
  margin-bottom: calc(1.25rem * 1.5 / 2);
}

.c-social-bar__list {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  margin-bottom: 0;
}

.c-social-bar__item {
  flex: 1 0 25%;
  max-width: 25%;
  text-align: left;
  border: 1px solid #fff;
}

.c-social-bar__button {
  width: 100%;
  padding: 0;
  color: #000;
}

.c-social-bar__button:hover {
  color: #182f7a !important;
  background: #eed343 !important;
}

.c-social-bar__button:visited {
  color: #182f7a !important;
}

.c-social-bar__text {
  display: none;
}

.c-social-bar__button--facebook {
  color: #212529;
  background-color: whitesmoke;
  border-color: whitesmoke;
}

.c-social-bar__button--facebook:hover {
  color: #212529;
  background-color: #eed343;
  border-color: #eed343;
}

.c-social-bar__button--facebook:focus, .c-social-bar__button--facebook.focus {
  color: #212529;
  background-color: #eed343;
  border-color: #eed343;
  box-shadow: 0 0 0 0.2rem rgba(213, 214, 214, 0.5);
}

.c-social-bar__button--facebook.disabled, .c-social-bar__button--facebook:disabled {
  color: #212529;
  background-color: whitesmoke;
  border-color: whitesmoke;
}

.c-social-bar__button--facebook:not(:disabled):not(.disabled):active, .c-social-bar__button--facebook:not(:disabled):not(.disabled).active,
.show > .c-social-bar__button--facebook.dropdown-toggle {
  color: #212529;
  background-color: #dcdbdb;
  border-color: #d5d5d5;
}

.c-social-bar__button--facebook:not(:disabled):not(.disabled):active:focus, .c-social-bar__button--facebook:not(:disabled):not(.disabled).active:focus,
.show > .c-social-bar__button--facebook.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(213, 214, 214, 0.5);
}

.c-social-bar__button--twitter {
  color: #212529;
  background-color: whitesmoke;
  border-color: whitesmoke;
}

.c-social-bar__button--twitter:hover {
  color: #212529;
  background-color: #eed343;
  border-color: #eed343;
}

.c-social-bar__button--twitter:focus, .c-social-bar__button--twitter.focus {
  color: #212529;
  background-color: #eed343;
  border-color: #eed343;
  box-shadow: 0 0 0 0.2rem rgba(213, 214, 214, 0.5);
}

.c-social-bar__button--twitter.disabled, .c-social-bar__button--twitter:disabled {
  color: #212529;
  background-color: whitesmoke;
  border-color: whitesmoke;
}

.c-social-bar__button--twitter:not(:disabled):not(.disabled):active, .c-social-bar__button--twitter:not(:disabled):not(.disabled).active,
.show > .c-social-bar__button--twitter.dropdown-toggle {
  color: #212529;
  background-color: #dcdbdb;
  border-color: #d5d5d5;
}

.c-social-bar__button--twitter:not(:disabled):not(.disabled):active:focus, .c-social-bar__button--twitter:not(:disabled):not(.disabled).active:focus,
.show > .c-social-bar__button--twitter.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(213, 214, 214, 0.5);
}

.c-social-bar__button--xing {
  color: #212529;
  background-color: whitesmoke;
  border-color: whitesmoke;
}

.c-social-bar__button--xing:hover {
  color: #212529;
  background-color: #eed343;
  border-color: #eed343;
}

.c-social-bar__button--xing:focus, .c-social-bar__button--xing.focus {
  color: #212529;
  background-color: #eed343;
  border-color: #eed343;
  box-shadow: 0 0 0 0.2rem rgba(213, 214, 214, 0.5);
}

.c-social-bar__button--xing.disabled, .c-social-bar__button--xing:disabled {
  color: #212529;
  background-color: whitesmoke;
  border-color: whitesmoke;
}

.c-social-bar__button--xing:not(:disabled):not(.disabled):active, .c-social-bar__button--xing:not(:disabled):not(.disabled).active,
.show > .c-social-bar__button--xing.dropdown-toggle {
  color: #212529;
  background-color: #dcdbdb;
  border-color: #d5d5d5;
}

.c-social-bar__button--xing:not(:disabled):not(.disabled):active:focus, .c-social-bar__button--xing:not(:disabled):not(.disabled).active:focus,
.show > .c-social-bar__button--xing.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(213, 214, 214, 0.5);
}

.c-social-bar__button--linkedin {
  color: #212529;
  background-color: whitesmoke;
  border-color: whitesmoke;
}

.c-social-bar__button--linkedin:hover {
  color: #212529;
  background-color: #eed343;
  border-color: #eed343;
}

.c-social-bar__button--linkedin:focus, .c-social-bar__button--linkedin.focus {
  color: #212529;
  background-color: #eed343;
  border-color: #eed343;
  box-shadow: 0 0 0 0.2rem rgba(213, 214, 214, 0.5);
}

.c-social-bar__button--linkedin.disabled, .c-social-bar__button--linkedin:disabled {
  color: #212529;
  background-color: whitesmoke;
  border-color: whitesmoke;
}

.c-social-bar__button--linkedin:not(:disabled):not(.disabled):active, .c-social-bar__button--linkedin:not(:disabled):not(.disabled).active,
.show > .c-social-bar__button--linkedin.dropdown-toggle {
  color: #212529;
  background-color: #dcdbdb;
  border-color: #d5d5d5;
}

.c-social-bar__button--linkedin:not(:disabled):not(.disabled):active:focus, .c-social-bar__button--linkedin:not(:disabled):not(.disabled).active:focus,
.show > .c-social-bar__button--linkedin.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(213, 214, 214, 0.5);
}

.c-meta-nav {
  display: block;
  padding-left: .25rem;
  padding-right: .25rem;
  text-align: center;
}

@media (min-width: 48em) {
  .c-meta-nav {
    padding-left: 0;
    text-align: left;
  }
}

.c-meta-nav__item {
  display: inline-block;
}

.c-meta-nav__item:not(:last-child):after {
  content: '\00B7';
  display: inline-block;
  margin-left: .25rem;
  margin-right: .25rem;
}

.c-meta-nav__link {
  color: #212529;
  font-family: "Taz-SemiBold";
  font-size: 1rem;
}

.c-meta-nav__link:hover {
  color: black;
}

.c-interferer--front-page {
  background: url("../assets/images/static-interferer.jpg") center no-repeat;
  background-size: cover;
  min-height: 400px;
}

@media (min-width: 992px) {
  .c-interferer--front-page {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .c-interferer--front-page {
    max-width: 1140px;
  }
}

@media (min-width: 48em) {
  .c-interferer--front-page {
    min-height: 530px;
  }
}

.c-interferer__content {
  padding: 3rem;
  color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
}

@media (min-width: 48em) {
  .c-interferer__content {
    padding: 6rem;
  }
}

.c-interferer__header {
  font-family: "Taz-SemiBold";
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1rem;
  text-align: left;
}

@media (min-width: 48em) {
  .c-interferer__header {
    font-size: 1.5rem;
    line-height: 1.5rem;
  }
}

@media (min-width: 62em) {
  .c-interferer__header {
    font-size: 2rem;
  }
}

.c-interferer__description {
  font-size: 1rem;
  line-height: 1rem;
}

@media (min-width: 62em) {
  .c-interferer__description {
    padding: 0;
  }
}

.c-list {
  display: block;
}

.c-list--grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.c-list--baseline {
  align-content: baseline;
}

.c-list--gutter {
  justify-content: space-between;
}

@media (min-width: 62em) {
  .c-list--gutter {
    margin-left: -calc(1.25rem * 1.5);
    margin-right: -calc(1.25rem * 1.5);
  }
}

.c-list--tiles {
  margin-bottom: calc(1.25rem * 1.5);
}

.c-list__item {
  margin-bottom: calc(calc(1.25rem * 1.5) * 1.5);
}

.c-list--grid .c-list__item {
  flex: 1 0 100%;
  max-width: 100%;
}

@media (max-width: 61.99em) {
  .c-list--grid .c-list__item--width-25 {
    flex: 1 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 62em) {
  .c-list--grid .c-list__item {
    flex: 1 0 33.3333%;
    max-width: 33.3333%;
  }
  .c-list--grid .c-list__item--width-25 {
    flex: 1 0 25%;
    max-width: 25%;
  }
}

.c-widget--hph-teaser-links-external .c-list__item {
  flex: 1 0 100%;
  max-width: 100%;
}

@media (min-width: 48em) {
  .c-widget--hph-teaser-links-external .c-list__item {
    flex: 1 0 33.3333%;
    max-width: 33.3333%;
  }
}

@media (min-width: 62em) {
  .c-widget--hph-teaser-links-external .c-list__item {
    flex: 1 0 33.3333%;
    max-width: 33.3333%;
  }
}

@media (min-width: 62em) {
  .c-list--gutter .c-list__item {
    padding-left: calc(1.25rem * 1.5);
    padding-right: calc(1.25rem * 1.5);
  }
}

@media (min-width: 62em) {
  .c-teaser--links .c-list__item {
    padding-left: calc(1.25rem * 1.5);
    padding-right: calc(1.25rem * 1.5);
  }
}

.c-list--tiles .c-list__item {
  display: block;
  padding: calc(1.25rem * 1.5);
  margin-bottom: 0;
  border: 1px solid #212529;
  border-top-width: 0;
  color: #212529;
}

.c-list--tiles .c-list__item:first-child {
  border-top-width: 1px;
}

c-list--bordered .c-list__item {
  border-bottom: 1px solid #212529;
}

c-list--bordered .c-list__item:last-of-type {
  border-bottom: 0px;
}

.c-list__item--card {
  position: relative;
}

.c-list__item--card:not(:last-child)::after {
  content: "";
  position: absolute;
}

@media (max-width: 61.99em) {
  .c-list__item--card:not(:last-child)::after {
    bottom: -calc(calc(calc(1.25rem * 1.5) * 1.5) / 2);
    left: 0;
    width: 90px;
    height: 0.25rem;
    background-color: #eed343;
  }
}

@media (min-width: 62em) {
  .c-list__item--card:not(:last-child)::after {
    top: 0;
    right: 0;
    width: 1px;
    height: 100%;
    background-color: #212529;
  }
}

.c-teaser--links .c-list__item--card::after {
  display: none;
}

.c-list__item--action {
  color: #4e5862;
}

.c-list__item--action:hover {
  text-decoration: none;
  background-color: #efefef;
  color: #212529;
}

.c-card-list .c-card-list__item {
  padding-right: calc(1.25rem * 1.5);
}

.c-card-list .c-card-list__item:not(:first-of-type) {
  padding-left: calc(1.25rem * 1.5);
}

@media (min-width: 48em) {
  .c-card-list .c-card-list__item:not(last) {
    border-right: 1px solid #5a5f64;
  }
}

.c-card-list .c-card-list__item:last-of-type {
  border-right: 0;
}

.c-filter__section {
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
}

.c-filter__block {
  flex: 1 0 0%;
}

.c-filter__block--action {
  text-align: right;
}

.c-filter__block--container {
  border: 1px solid #f8f9fa;
}

.c-filter__block--hidden {
  display: none;
}

.c-filter__items {
  width: 100%;
  display: block;
}

@media (min-width: 48em) {
  .c-filter__items {
    display: flex;
    flex-direction: row;
  }
}

.c-filter__item {
  flex: 1 0 0%;
  margin-bottom: .5rem;
}

@media (min-width: 48em) {
  .c-filter__item {
    flex: 1 0 25%;
    padding-right: .5rem;
  }
}

.c-filter__item:last-child {
  padding-right: 0;
}

.c-filter-item {
  width: 100%;
}

.c-filter-item__header {
  padding: .5rem 1rem;
  background: #efefef;
  font-weight: 700;
  font-family: "Taz-SemiLight";
  margin-bottom: .5rem;
}

.c-filter-list__result-count {
  margin-bottom: 1rem;
  font-weight: 700;
  font-family: "Taz-SemiLight";
}

.c-filter-list__result-index {
  background: #efefef;
  padding: 1rem;
  margin-top: 1rem;
}

.c-collapsible__header {
  display: flex;
  width: 100%;
  background: whitesmoke;
  padding: 0.5rem 1rem;
  border-bottom: 0.125rem solid #eed343;
}

@media (max-width: 74.99em) {
  .c-collapsible__header {
    cursor: pointer;
    border-bottom: 0.125rem solid #eed343;
  }
}

@media (min-width: 62em) {
  .c-collapsible__header--hidden-lg {
    display: none;
  }
}

@media (min-width: 75em) {
  .c-collapsible__header--hidden-xl {
    display: none;
  }
}

.c-collapsible__item {
  max-height: 0;
  overflow: hidden;
  transition: max-height .75s ease-in-out;
}

@media (min-width: 48em) {
  .c-collapsible__item--md {
    max-height: 1500px;
  }
}

@media (min-width: 62em) {
  .c-collapsible__item--lg {
    max-height: 1500px;
  }
}

.c-collapsible__item--active {
  display: block;
  max-height: 1500px;
}

@media (min-width: 75em) {
  .c-collapsible__item--active {
    max-height: 1500px;
  }
}

.c-collapsible__link {
  flex: 1 0 50%;
  max-width: 50%;
  margin-bottom: 0;
  font-family: "Taz-SemiLight";
}

@media (min-width: 62em) {
  .c-card--nav .c-collapsible__link {
    flex: 1 0 100%;
    max-width: 100%;
  }
}

.c-collapsible__link--icon {
  text-align: right;
  color: currentColor;
}

@media (min-width: 75em) {
  .c-collapsible__link--icon {
    display: none;
  }
}

@media (min-width: 62em) {
  .c-card--nav .c-collapsible__link--icon {
    display: none;
  }
}

@media (min-width: 75em) {
  .c-collapsible__link {
    flex: 1 0 100%;
    max-width: 100%;
  }
}

.c-collapsible__icon {
  color: currentColor;
}

.c-collapsible__icon--close {
  display: none;
}

.show .c-collapsible__icon--open {
  display: none;
}

.show .c-collapsible__icon--close {
  display: inline-block;
}

.c-teaser {
  width: 100%;
  overflow: hidden;
}

.c-teaser__header {
  margin-bottom: calc(1.25rem * 1.5);
  color: #000;
}

.c-teaser__list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.c-teaser__footer {
  text-align: right;
  font-family: "Taz-SemiBold";
  padding-bottom: 1rem;
}

@media (min-width: 62em) {
  .c-teaser__footer {
    padding-bottom: 0;
  }
}

.c-teaser__link {
  text-decoration: none;
}

.c-teaser__link:hover {
  text-decoration: none;
}

.c-event-info__title {
  margin-bottom: 0;
  font-family: "Taz-SemiBold";
}

@media (min-width: 48em) {
  .c-link-tile {
    display: flex;
    flex-direction: column;
  }
}

.c-link-tile__aside {
  width: 100%;
  max-width: 100%;
}

@media (min-width: 48em) {
  .c-link-tile__aside {
    flex: 0 0 25%;
    max-width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.c-link-tile__main {
  width: 100%;
  text-align: center;
}

@media (min-width: 48em) {
  .c-link-tile__main {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (min-width: 48em) {
  .c-link-tile__aside,
  .c-link-tile__main {
    width: 100%;
    max-width: 100%;
  }
}

.c-link-tile__asset {
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5rem;
  height: 5rem;
  border: 2px solid whitesmoke;
  border-radius: 50%;
}

@media (min-width: 48em) {
  .c-link-tile__asset {
    width: 8rem;
    height: 8rem;
  }
}

.c-link-tile__icon {
  width: 3.5rem;
  height: 3.5rem;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 48em) {
  .c-link-tile__icon {
    width: 5rem;
    height: 5rem;
  }
}

.c-link-tile__header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: calc(1.25rem * 1.5);
}

@media (min-width: 48em) {
  .c-link-tile__header {
    text-align: center;
  }
}

.c-link-tile__title {
  font-size: 1.25rem;
}

.c-poster {
  display: block;
  position: relative;
  width: 100%;
}

.c-poster__main {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 1rem;
  color: #fff;
}

@media (min-width: 48em) {
  .c-poster__main {
    padding: 3rem;
  }
}

.c-poster__header {
  font-family: "Taz-SemiBold";
  font-size: 1.5rem;
  line-height: 1.5;
}

@media (min-width: 48em) {
  .c-poster__header {
    line-height: 1.5;
  }
}

.c-poster__description {
  font-size: 1.25rem;
  line-height: 1.5;
}

.c-poster__link {
  color: #fff;
  text-decoration: none;
}

.c-poster__link:hover {
  color: #fff;
}

.c-poster__text a {
  color: #fff;
}

.c-poster__text a:hover {
  color: #fff;
}

.c-slider {
  display: block;
  width: 100vw;
  margin-left: -calc(1.25rem * 1.5);
  margin-right: -calc(1.25rem * 1.5);
}

@media (min-width: 62em) {
  .c-slider {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

.c-slider__main {
  width: 100%;
  background: #fff;
}

.c-slider__items {
  display: block;
  position: relative;
  width: 100%;
}

.c-slide {
  display: block;
  position: relative;
  width: 100%;
}

.c-slide__main {
  position: relative;
  padding: 2rem 2rem .5rem 2rem;
  color: #212529;
  width: 100%;
  background: #fff;
}

@media (min-width: 48em) {
  .c-slide__main {
    position: absolute;
    bottom: 0;
    left: 0;
    color: #fff;
    width: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.45));
    padding: 3rem 5rem;
  }
}

.c-slide__content a {
  color: #182f7a;
}

.c-slide__content a:hover {
  color: #182f7a;
}

.c-slide__content a:visited {
  color: #182f7a;
}

@media (min-width: 48em) {
  .c-slide__content {
    padding-bottom: 0;
  }
  .c-slide__content a {
    color: #fff;
  }
  .c-slide__content a:hover {
    color: #fff;
  }
  .c-slide__content a:visited {
    color: #fff;
  }
}

.c-slider__items {
  margin-bottom: 0;
}

.c-slide__figure {
  margin-bottom: 0;
}

/* ---- flickity overrides ---- */
.flickity-prev-next-button {
  background: transparent;
}

@media (min-width: 62em) {
  .flickity-prev-next-button {
    width: 100px;
    height: 100px;
  }
}

.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 20%;
  top: -280%;
  width: 60%;
  height: 60%;
}

@media (min-width: 48em) {
  .flickity-prev-next-button .flickity-button-icon {
    top: 20%;
  }
}

.flickity-prev-next-button .arrow {
  fill: #fff;
}

.flickity-prev-next-button:hover {
  background: none;
}

.flickity-page-dots {
  display: none;
  text-align: center;
  position: absolute;
  top: 55%;
  padding-top: 0;
  max-height: 30px;
}

@media (min-width: 48em) {
  .flickity-page-dots {
    display: block;
    border: 0;
    top: 85%;
    bottom: 2rem;
    padding-top: 1rem;
  }
}

@media (min-width: 75em) {
  .flickity-page-dots {
    top: 90%;
  }
}

.flickity-page-dots .dot {
  background-color: #fff;
}

@media (min-width: 48em) {
  .flickity-page-dots .dot {
    background-color: #fff;
  }
}

.c-pane {
  margin: 0;
}

.c-pane__header {
  width: 100%;
  cursor: pointer;
}

.c-pane__title {
  display: flex;
  width: 100%;
  padding: calc(1.25rem * 1.5 / 2);
  margin-bottom: 0;
  border-bottom: 1px solid #efefef;
  background-color: whitesmoke;
}

.c-pane__headline {
  flex: 0 1 calc(100% - 4rem);
}

.c-pane__toggle {
  flex: 0 1 4rem;
  text-align: right;
}

.c-pane__main {
  display: none;
  transition: ease .3s all;
  animation-name: fadeIn;
  animation-duration: .6s;
  animation-fill-mode: both;
  will-change: transform;
}

.c-pane__main--active, .c-pane__main.c-collapsible__item--active {
  display: block;
}

.c-pane__content {
  display: block;
  padding: calc(1.25rem * 1.5) calc(1.25rem * 1.5 / 2);
}

.c-pane__icon {
  color: currentColor;
}

.c-pane__icon--close {
  display: none;
}

.show .c-pane__icon--open {
  display: none;
}

.show .c-pane__icon--close {
  display: inline-block;
}

.c-page-header {
  margin: 0 auto 1.5rem;
}

@media (min-width: 992px) {
  .c-page-header {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .c-page-header {
    max-width: 1140px;
  }
}

.c-page-header__title {
  margin-bottom: 1.5rem;
}

.c-page-header__headline {
  padding: .125rem 0;
  color: #000;
  line-height: 1.25;
}

.c-page-header__abstract {
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.5;
}

@media (max-width: 1200px) {
  .c-page-header__abstract {
    font-size: calc(1.275rem + 0.3vw) ;
  }
}

.c-widget--hph-content-alias {
  margin-top: -calc(1.25rem * 1.5);
  margin-bottom: -calc(1.25rem * 1.5);
}

.c-widget {
  display: block;
}

.c-widget--card {
  width: 100%;
}

.c-widget__header {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  color: #fff;
  background-color: #000;
}

.c-widget__content--header {
  font-family: "Taz-SemiBold";
  font-size: 2rem;
}

@media (min-width: 992px) {
  .c-widget__content--header {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .c-widget__content--header {
    max-width: 1140px;
  }
}

@media (max-width: 1200px) {
  .c-widget__content--header {
    font-size: calc(1.325rem + 0.9vw) ;
  }
}

.c-widget__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
  border: 1px solid #adb5bd;
  margin-bottom: calc(1.25rem * 1.5 / 2);
}

.c-widget__icon--centered {
  margin-left: auto;
  margin-right: auto;
}

.c-widget__icon--default {
  width: 4rem;
  height: 4rem;
}

.c-widget__editor {
  display: block;
}

.c-widget__editor:hover {
  cursor: text;
}

.ph-item {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding: 0 0 -15px 0;
  overflow: hidden;
  margin-bottom: 0;
  background-color: #fff;
  border: 1px solid #e6e5e5;
  border-radius: 0;
}

.ph-item, .ph-item *,
.ph-item ::after, .ph-item ::before {
  box-sizing: border-box;
}

.ph-item::before {
  content: " ";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 50%;
  z-index: 1;
  width: 500%;
  margin-left: -250%;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 46%, rgba(255, 255, 255, 0.35) 50%, rgba(255, 255, 255, 0) 54%) 50% 50%;
}

.ph-item > * {
  flex: 1 1 auto;
  display: flex;
  flex-flow: column;
  padding-right: 0;
  padding-left: 0;
}

.ph-row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 7.5px;
}

.ph-row div {
  height: 10px;
  margin-bottom: 7.5px;
  background-color: #ced4da;
}

.ph-row .big,
.ph-row.big div {
  height: 20px;
  margin-bottom: 15px;
}

.ph-row .empty {
  background-color: rgba(255, 255, 255, 0);
}

.ph-col-2 {
  flex: 0 0 16.6666666667%;
}

.ph-col-4 {
  flex: 0 0 33.3333333333%;
}

.ph-col-6 {
  flex: 0 0 50%;
}

.ph-col-8 {
  flex: 0 0 66.6666666667%;
}

.ph-col-10 {
  flex: 0 0 83.3333333333%;
}

.ph-col-12 {
  flex: 0 0 100%;
}

.ph-avatar {
  position: relative;
  width: 100%;
  min-width: 60px;
  background-color: #ced4da;
  margin-bottom: 15px;
  border-radius: 50%;
  overflow: hidden;
}

.ph-avatar::before {
  content: " ";
  display: block;
  padding-top: 100%;
}

.ph-picture {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 120px;
  color: #fff;
  background-color: #ced4da;
  margin-bottom: 15px;
}

@keyframes phAnimation {
  0% {
    transform: translate3d(-30%, 0, 0);
  }
  100% {
    transform: translate3d(30%, 0, 0);
  }
}

.c-widget-selector {
  display: block;
}

.c-widget-selector__section {
  margin-bottom: 1;
}

.c-widget-selector__header {
  padding: .5rem;
  margin-bottom: 1rem;
  border-bottom: 0.0125rem solid #212529;
}

.c-widget-selector__headline {
  font-family: "Taz-SemiBold";
  text-transform: uppercase;
}

.c-widget-selector__item {
  display: block;
  opacity: .8;
  padding: calc(1.25rem * 1.5 / 2);
  color: #212529;
  border: .125rem solid transparent;
  /* <- here */
  transition: border-color 1s ease;
}

.c-widget-selector__item:hover {
  opacity: 1;
  border: 0.125rem solid #5a6570;
  text-decoration: none;
}

.c-widget-selector__item--selected {
  opacity: 1;
  border: 0.125rem solid #212529;
}

.c-widget-editor__item {
  display: flex;
  justify-content: space-between;
  padding: calc(1.25rem * 1.5 / 2);
  border: 0.125rem solid #999999;
  margin-bottom: 1rem;
}

.c-widget-editor__item--secondary {
  margin-left: 5rem;
}

.c-widget-editor__item-aside {
  flex: 0 0 4rem;
  max-width: 4rem;
  height: 3rem;
  border-right: 1px solid #999999;
  color: #999999;
}

.c-widget-editor__item-actions {
  flex: 0 0 16rem;
  max-width: 16rem;
  text-align: right;
}

.c-widget-editor__item-main {
  flex: 1 0 auto;
  max-width: 80%;
  padding-left: calc(1.25rem * 1.5 / 2);
  padding-right: calc(1.25rem * 1.5 / 2);
}

.c-widget-editor__item-main--centered {
  display: flex;
  align-items: center;
}

.c-widget-editor__item-title {
  margin: 0;
  line-height: 1;
}

.hidden,
.hiddenStructure {
  visibility: hidden;
}

.u-visibility--hidden,
.hiddenStructure {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  color: #fff;
  background-color: #000;
}

.u-visibility--hidden a,
.hiddenStructure a {
  color: #fff;
  background-color: #000;
}

.u-backdrop {
  background-image: none !important;
  -o-animation: fadeIn .5s ease-in-out;
  animation: fadeIn .5s ease-in-out;
}

.u-backdrop:before {
  content: "";
  display: block;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  cursor: pointer;
}

.u-backdrop--inverse::before {
  background: rgba(255, 255, 255, 0.5);
}

.u-backdrop--solid::before {
  background: white;
}

.u-device--ios * {
  cursor: pointer;
}

.u-display--block {
  display: block;
}

.u-display--none {
  display: none;
}

@media (min-width: 36em) {
  .u-display-sm--none {
    display: none !important;
  }
  .u-display-sm--block {
    display: block !important;
  }
}

@media (min-width: 48em) {
  .u-display-md--none {
    display: none !important;
  }
  .u-display-md--block {
    display: block !important;
  }
}

@media (min-width: 62em) {
  .u-display-lg--none {
    display: none !important;
  }
  .u-display-lg--block {
    display: block !important;
  }
}

@media (min-width: 75em) {
  .u-display-xl--none {
    display: none !important;
  }
  .u-display-xl--block {
    display: block !important;
  }
}

@media (min-width: 277777.75em) {
  .u-display-navbar--none {
    display: none !important;
  }
  .u-display-navbar--block {
    display: block !important;
  }
}

.u-display--full-width {
  width: 100vw;
  margin-left: 50%;
  transform: translateX(-50%);
}

.u-color--muted {
  color: #6c757d;
}

.u-text--word-wrap {
  word-wrap: break-word;
}

.u-text--sm {
  font-size: 1rem;
}

.u-text--muted {
  color: #5a6570;
}

[id="personal-bar-container"] {
  z-index: 105;
}
