.u-backdrop {
    background-image:none !important;
    -o-animation: fadeIn .5s ease-in-out;
    animation: fadeIn .5s ease-in-out;
    &:before {
        content: "";
        display: block;
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, .5);
        z-index: 999;
        cursor: pointer;
    }
    &--inverse {
        &::before {
            background: rgba(255, 255, 255, .5);
        }
    }
    &--solid {
        &::before {
            background: rgba(255, 255, 255, 1);
        }
    }
}
