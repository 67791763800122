//== Course list filter bar
.c-filter__section {
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
}

.c-filter__block {
    flex: 1 0 0%;
    &--action {
        text-align: right;
        //padding-right: 1rem;
    }
    &--container {
        border: 1px solid $gray-100;
    }
    &--hidden {
        display: none;
    }
}

.c-filter__items {
    width: 100%;
    display: block;
    @include mq($from: md) {
        display: flex;
        flex-direction: row;
    }

    &--unit {
    }
}

.c-filter__item {
    flex: 1 0 0%;
    margin-bottom: .5rem;
    @include mq($from: md) {
        flex: 1 0 25%;
        padding-right: .5rem;
    }
    &:last-child {
        padding-right: 0;
    }
}

.c-filter-item {
    width: 100%;
}

.c-filter-item__header {
    padding: .5rem 1rem;
    background: $app-color-gray-light;
    font-weight: $app-font-weight-strong;
    font-family: $app-headings-font-family-secondary;
    margin-bottom: .5rem;
}

.c-filter-list__result-count {
    margin-bottom: 1rem;
    font-weight: $app-font-weight-strong;
    font-family: $app-headings-font-family-secondary;
}


.c-filter-list__result-index {
    background: $app-color-gray-light;
    padding: 1rem;
    margin-top: 1rem;
}
